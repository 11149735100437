import "./MultiSelectDirectiveStyles.css";
import { useState, useEffect, useCallback, useRef, createRef } from "react";
import useScreenDetector from "../hooks/useScreenDetector";
import SelectOptionsTextComponent from "./SelectOptionsTextComponent";
import { IoClose } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

function MultiSelectDirectiveNewComponent({placeholder, options, SetResult, defSelectedItems}){
    const [isOpened, SetIsOpened] = useState(false);
    const [isEmpty, SetIsEmpty] = useState(true);
    // const [selectedItems, setSelectedItems] = useState([]);
    const [myItems, SetMyItems] = useState([]);
    const [generatedId, SetGeneratedId] = useState("");
    const myRef = useRef(null);
    const listRef = createRef(null);

    const { sizeScreen } = useScreenDetector();

    useEffect(() => {
        if(!!myRef && !!listRef && !!listRef.current){
            let element = myRef.current.getBoundingClientRect();
            listRef.current.style.width = element.width + "px";
        }
    }, [sizeScreen, myRef, listRef])

    // useEffect(() => {
    //     if(!!defSelectedItems){
    //         setSelectedItems(defSelectedItems);
    //     }
    // }, [defSelectedItems])

    const clickOutside = useCallback((event) => {
        const el = myRef?.current
        if (!el || el.contains(event.target)) {
            SetIsOpened(true)
            return
        }
        SetIsOpened(false)
    }, [myRef])

    useEffect(() => {
        window.addEventListener('click', clickOutside);
        return () => {
            window.removeEventListener('click', clickOutside);
        }
    }, []);

    useEffect(() => {
        if(!!myRef){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("selectDirective".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    useEffect(() => {
        if(!!options){
            SetMyItems(options);
        }
    }, [options])

    useEffect(() => {
        SetIsEmpty(defSelectedItems.length == 0);
    }, [defSelectedItems, isEmpty])

    const selectItemFunction = useCallback((item) =>{
        let sel = defSelectedItems;
        if(!defSelectedItems.some(({value}) => value === item.value)){
            sel.push(item);
        }
        // setSelectedItems(sel);
        let my = myItems.filter(x => x.value != item.value);
        SetMyItems(my);

        SetIsEmpty(false);
        SetResult(sel);
    },[defSelectedItems, myItems])

    const removeItemFunction = useCallback((e, item) => {
        let sel = defSelectedItems.filter(x => x.value != item.value);
        // setSelectedItems(sel);

        let my = options.filter(x => !sel.some(({value}) => value === x.value));
        SetMyItems(my);
        SetResult(sel);
        e.stopPropagation();
    }, [defSelectedItems, myItems, options])

    const clear = useCallback((e) =>{
        SetMyItems(options);
        // setSelectedItems([]);
        SetResult([]);
        e.stopPropagation();
    },[defSelectedItems, myItems, options])

    return (
        <div id={generatedId} ref={myRef} className="msd-main" tabIndex={0} onFocus={(e) => SetIsOpened(true)} onBlur={(e) => SetIsOpened(false)}>
            <div className={`msd-input-body ${!!isOpened ? "msd-focused" : ""} ${!isEmpty ? "msd-selected" : ""}`} >
                <div className="msd-input-inside">
                    {!!placeholder && defSelectedItems.length == 0 && <div className="msd-placeholder-style">{placeholder}</div>}
                    {defSelectedItems.length > 0 && 
                        <div className="msd-selected-items-style">
                            {defSelectedItems.map((item, idx) => (
                                <div key={idx} className="msd-selected-item">
                                    <div className="msd-selected-item-text">{item.text}</div>
                                    <div className="msd-selected-item-remove" onClick={(e) => {removeItemFunction(e, item)}}>
                                        <IoClose className="msd-selected-item-remove-button"/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="msd-clear">
                    {defSelectedItems.length > 0 && 
                    <IoClose className="msd-clear-button"
                        onClick={(e) => {
                            clear(e);
                        }} 
                    />}
                    {defSelectedItems.length == 0 && !!isOpened &&
                    <FaChevronUp className="msd-oc-button"/>
                    }
                    {defSelectedItems.length == 0 && !isOpened &&
                    <FaChevronDown className="msd-oc-button"/>
                    }
                </div>
            </div>
            {!!isOpened && 
                <SelectOptionsTextComponent options={myItems} selectedOptions={defSelectedItems} onChange={selectItemFunction} listRef={listRef} />
            }
        </div>
    )
}

export default MultiSelectDirectiveNewComponent;