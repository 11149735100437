import "./ModalItem.css";
import { useState } from "react";
import Modal from '@mui/material/Modal';

function ModalPromotionComponent({promotion, onClose, open}) {
  return promotion && (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <div className="restaurant-start-inside ql-editor" dangerouslySetInnerHTML={{__html: promotion.content}} />
      </Modal>
    </>
    
  );
}

export default ModalPromotionComponent;
