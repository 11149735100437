import { useCallback } from "react";

function useSortHelper() {


  const getValueOfProperty = useCallback((prop, field) => {
    return prop[field];
  }, [])

  const getValueByField = useCallback((prop, field) => {
    if(!field){
        return prop;
    }
    let fields = field.split('/');
    let res = prop;
    fields.forEach(element => {
      res = getValueOfProperty(res, element);
    });
    return res;
  }, [])

  const sortNumericFunction = useCallback((a, b, field) => {
    let aValue = Number(getValueByField(a, field));
    let bVAlue = Number(getValueByField(b, field));

    if ( aValue < bVAlue ){
      return -1;
    }
    if ( aValue > bVAlue ){
      return 1;
    }
    return 0;
  }, [])

  const sortAlphabetFunction = useCallback((a, b, field) => {
    let aValue = getValueByField(a, field).toLowerCase();
    let bVAlue = getValueByField(b, field).toLowerCase();

    if ( aValue < bVAlue ){
      return -1;
    }
    if ( aValue > bVAlue ){
      return 1;
    }
    return 0;
  }, [])

  const SortAlphabetArray = useCallback((array, type, field) => {
    if(type == "desc"){
      return array.sort((a, b) => sortAlphabetFunction(b, a, field));
    }
    else{
      return array.sort((a, b) => sortAlphabetFunction(a, b, field));
    }
  }, []);

  const SortNumericArray = useCallback((array, type, field) => {
    if(type == "desc"){
      return array.sort((a, b) => sortNumericFunction(b, a, field));
    }
    else{
      return array.sort((a, b) => sortNumericFunction(a, b, field));
    }
  }, []);


  return {
    SortAlphabetArray, SortNumericArray
  };
}

export default useSortHelper;
