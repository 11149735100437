import "../../css/restaurant.css";
import { GoDotFill } from "react-icons/go";
import { useI18nContext } from '../../i18n/context/context';

function HomeRestaurantBasicDataComponent({ restaurant }) {
  const { translate } = useI18nContext();
  return (
    <div>
        <div className="display-flex">
          <div className="hm-restaurant-details-cointener padding-bottom-sm">
            <div className="hm-restaurant-description-style">
              {!!restaurant.restaurantType && (
                <span className="hm-restaurant-label">
                  {translate("restaurantType")}
                </span>
              )}
              {!!restaurant.restaurantType && (
                <span>{restaurant.restaurantType}</span>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!restaurant.address && (
                <span className="hm-restaurant-label">{translate("address")}</span>
              )}
              {!!restaurant.address && (
                <span>
                  {restaurant.address.street} {restaurant.address.houseNr}
                  {!!restaurant.address.localNr &&
                    "/".concat(restaurant.address.localNr)}
                  , {restaurant.address.city}
                </span>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!restaurant.openingHours && (
                <span className="hm-restaurant-label">
                  {translate("openingHours")}
                </span>
              )}
              {!!restaurant.openingHours && (
                <div className="hm-opening-days"> 
                {  
                  restaurant.openingHours.map((item, index) => 
                  (
                    <div key={index}> {translate(item.days[0])} {item.days.length == 2 && ", "} {item.days.length > 2 && " - "} {item.days.length > 1 && translate(item.days[item.days.length-1])}</div>
                  ))
                }
                </div>
              )}
              {!!restaurant.openingHours && (
                <div> 
                {  
                  restaurant.openingHours.map((item, index) => 
                  (
                    <div key={index}>{item.time}</div>
                  ))
                }
                </div>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!restaurant.openNow && (
                <span><GoDotFill className="hm-restaurant-open-now" />{translate("openNow")}</span>
              )}
              {!restaurant.openNow && (
                <span><GoDotFill className="hm-restaurant-close-now" />{translate("closeNow")}</span>
              )}
            </div>
          </div>
        </div>
    </div>
  );
}

export default HomeRestaurantBasicDataComponent;
