import "../App.css";
import Modal from '@mui/material/Modal';
import { Loader } from 'rsuite';

function LoaderDirectiveComponent({}) {
  return (
    <>
      <Modal
        open={true}>
            <div className="modal-loader-container">
                <Loader size="lg" className='dropzone-icon' />
            </div>             
      </Modal>
    </>
  );
}

export default LoaderDirectiveComponent;
