function HomeItemPicturesComponent({ items, OpenModal }) {
    return (
      <div className="hm-pictures-style">
        {!!items && 
              items.map((item, idx) => (
                <div key={idx} className="hm-item-picture-container map-pictures-item-picture-container">
                    <img
                        src={item}
                        className="hm-item-img-style"
                        onClick={() => {
                        OpenModal(item);
                    }}
                    />
                </div>
              ))
          }
      </div>
    );
  }
  
  export default HomeItemPicturesComponent;