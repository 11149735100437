import "./InputDirectiveStyles.css";
import { useState, useEffect, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";

function InputDirectiveComponent({placeholder, defValue="", SetValue, type, isSearch, focusInCallback, focusOutCallback, clearCallback, className}){
    const [isFocused, setIsFocused] = useState(false);
    const [value, setVal] = useState("");
    const [generatedId, SetGeneratedId] = useState("");
    const myRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if(!!myRef){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("inputDirective".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    useEffect(() => {
        SetValue(value);
    }, [value])

    useEffect(() => {
        setVal(defValue);
    }, [defValue])

    const setFocus = useCallback(() => {
        if(!!inputRef && !value){
            inputRef.current.focus();
        }
    }, [inputRef, value])

    const focusInput = useCallback((e) => {
        setIsFocused(true);
        if(!!focusInCallback){
            focusInCallback();
        }
    }, [])

    const focusOutInput = (e) => {
        setIsFocused(false);
        if(focusOutCallback){
            focusOutCallback(value);
        }
    }

    const clear = useCallback((e) =>{
        setVal("");
        if(!!clearCallback){
            clearCallback();
        }
        e.stopPropagation();
    },[value])

    return (
        <div id={generatedId} ref={myRef} className={className}>
            <div className={`id-input-body ${!!isFocused ? "id-focused" : ""} ${!!value ? "id-not-empty" : ""}`}>
                {!!isSearch && !isFocused && !value &&
                    <div className="id-search" onClick={setFocus}>
                        <LuSearch className="id-search-button" />
                    </div>
                }
                {type == "number" && <input id={generatedId} placeholder={placeholder} ref={inputRef} type={type} className="id-input-inside" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} value={value ?? ''} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => setVal(e.currentTarget.value)}/>}
                {type != "number" && <input id={generatedId} placeholder={placeholder} ref={inputRef} type={type} className="id-input-inside" value={value ?? ''} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => setVal(e.currentTarget.value)}/>}
                <div className="id-clear" onClick={setFocus}>
                    {!!value && 
                    <IoClose className="id-clear-button"
                        onClick={(e) => {
                            clear(e);
                        }} 
                    />}
                </div>
            </div>
        </div>
    )
}

export default InputDirectiveComponent;