export const getFormattedAddress = (address) => {
  return `${address.street} ${[address.streetNumber, address.houseNumber].filter(number => number).join('/')}`;
};

export const getFormattedCurrency = (currencyCode) => {
  const currencyMapper = {
    // [Currency.EUR]: '€',
    // [Currency.PLN]: 'zł',
    [Currency.EUR]: 'EUR',
    [Currency.PLN]: 'PLN',
  };

  return currencyMapper[currencyCode] ?? '∅';
};

export const Currency = {
  EUR: 'EUR',
  PLN: 'PLN'
};

export const Allergen = {
  MILK: 'allergen:milk',
  EGGS: 'allergen:eggs',
  FISH: 'allergen:fish',
  CRUSTACEAN_SHELLFISH: 'allergen:crustacean-shellfish',
  TREE_NUTS: 'allergen:tree-nuts',
  PEANUTS: 'allergen:peanuts',
  WHEAT: 'allergen:wheat',
  SOYBEANS: 'allergen:soybeans',
  SESAME: 'allergen:sesame',
}

export const generateGuid = () => {
  return Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
}