import React, { useEffect, useState } from "react";
import DropzoneDirectiveComponent from "../../../directives/DropzoneDirectiveComponent";
import AdminPicturesItemComponent from "./AdminPicturesItemComponent";
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';
import { useLogger } from '../../../hooks/logger/useLogger';

function AdminPicturesComponent({ restaurantId, restaurantPictures }) {
  const logger = useLogger();
  const [medias, setMedias] = useState(restaurantPictures);

  useEffect(() => {
    const fetch = async () => {
      const response = await new ApiLibrary().restaurants.media.getList(restaurantId);
      setMedias(response.data);
    }

    fetch();
  }, [restaurantId]);

  const removeMedia = async (item) => {
    try {
      const response = await new ApiLibrary().restaurants.media.remove(restaurantId, item.id);
      setMedias((medias) => medias.filter(({id}) => id !== response.data?.id))
    } catch (error) {
      logger.error(error);
    }
  };

  const addMedia = async (files) => {
    try {
      const responses = await Promise.all(files.map((file) => {
        return new ApiLibrary().restaurants.media.add(restaurantId, file);
      }));

      if (!responses?.length) {
        return
      }

      setMedias((medias) => ([...medias, ...responses.map(response => response.data)]));
    } catch (error) {
      logger.error(error);
    }
  }

  if (!restaurantId) {
    return 'No restaurant';
  }

  return (
    <div className="admin-pictures-container">
      {!!medias && <AdminPicturesItemComponent
        medias={medias}
        onRemove={removeMedia}
      />}
      <div className="admin-pictures-dropzone">
        <DropzoneDirectiveComponent
          isMulti={true}
          // canLoading={false}
          onUpload={addMedia}
          className="dropzone-def-style"
        /> 
      </div>
    </div>
  );
}

export default AdminPicturesComponent;
