import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useMemo } from "react";
import "rsuite/dist/rsuite.min.css";
import { Dropdown } from "rsuite";
import { useI18nContext } from '../../../i18n/context/context';

function LanguageComponent({defLang}) {
  const { setLanguage, language, languageList, translate } = useI18nContext();

  const activeTitle = useMemo(() => (
    languageList.find(({ value }) => value === language)?.label
  ), [languageList, language])

  const changeLanguage = useCallback((value) => {
    setLanguage(value);
    // window.location.reload(false);
  }, []);

  return (
    <div>
      <Dropdown
        activeKey={language}
        title={activeTitle}
        placement="bottomEnd"
        className="header-right-style"
      >
        {languageList.map((item, key) => (
          <Dropdown.Item
            key={key}
            onSelect={changeLanguage}
            eventKey={item.value}
          >
            <>
              {(!defLang || defLang == item.value) && item.label}
              {(!!defLang && defLang != item.value) && 
                <>
                  {translate("translate")}: {item.label}
                </>
              }
            </>
          </Dropdown.Item> 
        ))}
      </Dropdown>
    </div>
  );
}

export default LanguageComponent;
