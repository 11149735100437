import "../../css/restaurant.css";
import React, { useState, useEffect, useCallback } from "react";
import RestaurantItemComponent from "./RestaurantItemComponent";

import { CircularProgress } from '@mui/material';

function RestaurantDemoItemsListComponent({ selectedRestaurant, onSelectedRestaurantChange, Search, filters, restaurants }) {
  const ChangeLocation = useCallback((res) => {
    window.location.href = window.location.href.concat(
      res.restaurantCode
    );
  }, [restaurants]);

  return (
    <div className="padding-left-md padding-right-md">
      {restaurants ? (!!restaurants.length &&
        restaurants.map((restaurant, index) => (
          <div
            key={index}
            onClick={() => onSelectedRestaurantChange(restaurant)}
          >
            <RestaurantItemComponent
              restaurant={restaurant}
            />
          </div>
        ))) : <CircularProgress />}
    </div>
  );
}

export default RestaurantDemoItemsListComponent;
