import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import {Icon} from 'leaflet'
import marker from "leaflet/dist/images/marker-icon.png"

function MapStaticComponent({positions}) {
  const [position] = useState([positions.x, positions.y]);

  const legalIcon = new Icon ({
    iconUrl: marker,
    iconSize: [25, 35]
  })
  
  const staticProps = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false
  }

  return (
    <div className="map-container-style">
      <MapContainer center={position} zoom={15} className="map-container" >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={legalIcon}>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default MapStaticComponent;
