import { BaseApi } from '../shared/baseApi';
import { createDeleteRequest, createPostRequest } from '../shared/fetch';
import { extractPublicId } from 'cloudinary-build-url';

export class CommonApi extends BaseApi {
  async add(file) {
    const data = new FormData();
    data.append('file', file);

    return await createPostRequest(
      `media`,
      data,
      this._language,
      null
    );
  }

  async removeByUrl(url) {
    return await createDeleteRequest(
      `media/${encodeURIComponent(extractPublicId(url))}`,
      this._language,
    );
  }
}