import React, { useCallback, useRef, useState, useMemo, useEffect }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import {Icon, divIcon} from 'leaflet'
import icon from "leaflet/dist/images/marker-icon.png"
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";


function AdminBasicMapComponent({ restaurant, isEditable, setLocation }) {

  const [position, setPosition] = useState({
    lat: restaurant?.location?.x,
    lng: restaurant?.location?.y
  })


  const provider = new OpenStreetMapProvider();

  const searchControl = new GeoSearchControl({
    provider: provider,
  });

  const legalIcon = new Icon ({
    iconUrl: icon,
    iconSize: [25, 35]
  })

  function DraggableMarker () {
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            let location = marker.getLatLng();
            setPosition(location);
            if(setLocation){
              setLocation(location)
            }
          }
        },
      }),
      [],
    )
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])
  
    return (
      <Marker
        draggable={isEditable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={legalIcon}>
      </Marker>
    )
  }

  // const staticProps = {
  //   zoomControl: false,
  //   doubleClickZoom: false,
  //   closePopupOnClick: false,
  //   dragging: false,
  //   zoomSnap: false,
  //   zoomDelta: false,
  //   trackResize: false,
  //   touchZoom: false,
  //   scrollWheelZoom: false
  // }

  return (
    <div className="admin-basic-map-container">
      <MapContainer center={position}  zoom={14} scrollWheelZoom={isEditable} className="admin-basic-map">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
      </MapContainer>
    </div>
    
  );
}

export default AdminBasicMapComponent;
