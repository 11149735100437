import { BaseApi } from '../shared/baseApi';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../shared/fetch';

export class DishesApi extends BaseApi {
  add(catalogId, categoryId, payload) {
    const searchParams = new URLSearchParams({
      catalogId,
      categoryId,
    });
    return createPostRequest(`product?${searchParams}`, JSON.stringify(payload), this._language);
  }

  update(dishId, payload) {
    return createPutRequest(`product/${dishId}`, JSON.stringify(payload), this._language);
  }

  remove(dishId) {
    return createDeleteRequest(`product/${dishId}`, this._language);
  }

  changeOrder(categoryId, dishIds) {
    const searchParams = new URLSearchParams({
      categoryId,
    });
    return createPutRequest(`product/xd/order?${searchParams}`, JSON.stringify({ids: dishIds}), this._language);
  }
}