import React, { useState, useRef, useCallback, useEffect } from "react";
import "../../../css/global.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useI18nContext } from '../../../i18n/context/context';
import useScreenDetector from "../../../hooks/useScreenDetector";

function NavBarScrollComponent({ items, setSelectedMenuItem, DefActiveTab }) {
  const { translate } = useI18nContext();
  const [selectedItem, setSelectItem] = useState(items[0]);
  const [canSelectItem, setCanSelectItems] = useState(true)
  const [itemsRefs, setItemsRefs] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const setItem = useCallback((item) => {
    setCanSelectItems(true);
    if(canSelectItem){
      setSelectItem(item);
      setSelectedMenuItem(item);
    }
  }, [canSelectItem, selectedItem]);
  useEffect(() => {
    if(!!DefActiveTab){
      setSelectItem(DefActiveTab);
    }
  }, [DefActiveTab])

  useEffect(() => {
    let refds = items.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setItemsRefs(refds);
  }, [items])
  
  useEffect(() =>{
    let sliderProperties = ourRef?.current?.children[0]?.getBoundingClientRect();
    let itemProperties = itemsRefs[selectedItem]?.current?.getBoundingClientRect();
    if(!!sliderProperties && !!itemProperties){
      if(itemProperties.x + itemProperties.width > sliderProperties.x + sliderProperties.width){
        let destPosition = ((itemProperties.x + itemProperties.width) - (sliderProperties.x + sliderProperties.width)) + ourRef.current.children[0].scrollLeft;
        ourRef.current.children[0].scrollTo({left: destPosition+30});
      }
      if(itemProperties.x < sliderProperties.x){
        let destPosition = ourRef.current.children[0].scrollLeft + itemProperties.x - sliderProperties.x;
        ourRef.current.children[0].scrollTo({left: destPosition-30});
      }
    }
  }, [selectedItem])

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const setArrowsInfo = useCallback((sliderMenu) => {
    setShowLeftArrow(sliderMenu.scrollLeft > 1);
    setShowRightArrow(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1)
  }, [showLeftArrow, showRightArrow])

  const handleDragStart = useCallback((e) => {
    if (!ourRef.current) return;
    const sliderMenu = ourRef.current.children[0];
    const startX = e.pageX - sliderMenu.offsetLeft;
    const startY = e.pageY - sliderMenu.offsetTop;
    const scrollLeft = sliderMenu.scrollLeft;
    const scrollTop = sliderMenu.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    document.addEventListener("mouseup", mouseDownOutsideComponent);
    setIsMouseDown(true);
  }, [ourRef, mouseCoords, isMouseDown]);

  const handleDragEnd = useCallback(() => {
    setIsMouseDown(false);
    setCanSelectItems(true);
    document.removeEventListener("mouseup", mouseDownOutsideComponent, false);
    if (!ourRef.current) return;
  }, [ourRef, isMouseDown]);
  
  const handleDrag = useCallback((e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const sliderMenu = ourRef.current.children[0];
    const x = e.pageX - sliderMenu.offsetLeft;
    const y = e.pageY - sliderMenu.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    sliderMenu.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    sliderMenu.scrollTop = mouseCoords.current.scrollTop - walkY;
    if(Math.abs(mouseCoords.current.scrollLeft - sliderMenu.scrollLeft) > 5){
      setCanSelectItems(false);
    }
  }, [isMouseDown, ourRef]);

  const handleWheel = useCallback((e) => {
    if (!ourRef.current || (ourRef.current.children[0].clientWidth >= ourRef.current.children[0].scrollWidth)) return;
    e.preventDefault()
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + (e.deltaY/5);
  }, [ourRef, showLeftArrow, showRightArrow]);

  const mouseDownOutsideComponent = useCallback((event) => {
    const el = ourRef?.current
    if (!el || el.contains(event.target)) {
        return;
    }
    handleDragEnd();
  }, [ourRef])

  const clickLeft = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft - 30;
    setArrowsInfo(sliderMenu)
  }

  const clickRight = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + 30;
    setArrowsInfo(sliderMenu)
  }

  const handleScroll = (e) => {
    setArrowsInfo(ourRef.current.children[0])
  }

  const { sizeScreen } = useScreenDetector();

  useEffect(() => {
    if(!ourRef)
      return;

    const sliderMenu = ourRef?.current.children[0];
    if(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1){
      sliderMenu.addEventListener("wheel", handleWheel)
      sliderMenu.addEventListener("scroll", handleScroll);
      setArrowsInfo(sliderMenu);
    }
    else{
      sliderMenu.removeEventListener("wheel", handleWheel, false)
      sliderMenu.removeEventListener("scroll", handleScroll, false);
      setArrowsInfo(sliderMenu);
    }
  }, [ourRef, sizeScreen])


  return (
    <div className="header-menu-scroll">
      {showLeftArrow &&
        (<div className="menu-left-arrow" onClick={clickLeft}>
          <FaChevronLeft size={20} className="menu-left-arrow-nav-bar-scroll"/>
        </div>)
      }
      
      <div
          ref={ourRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
          className="padding-left-md padding-right-md horizontal-menu-container scroll-horizontal"
          >
          
          <div id="sliderMenu" className="container-menu scroll-horizontal"> 
              {!!items &&
              items.map((item, idx) => (
                <div key={idx}>
                  <div onMouseUp={() => setItem(item)} ref={itemsRefs[item]} className={`main-manu-item ${item == selectedItem ? "main-menu-item-selected" : ""}`}>
                    {translate(item)}
                  </div>
                </div>
              ))}
              
          </div>
          {showRightArrow && 
            <div className="menu-right-arrow" onClick={clickRight}>
              <FaChevronRight size={20} className="menu-right-arrow-nav-bar-scroll"/>
            </div>
          }
      </div>
      
  </div>
    
  );
}

export default NavBarScrollComponent;
