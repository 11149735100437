import { BaseApi } from '../shared/baseApi';
import { createGetRequest, createPostRequest } from '../shared/fetch';

export class DescriptionApi extends BaseApi {
  get(restaurantCode) {
    return createGetRequest(`restaurants/${restaurantCode}/description`, this._language);
  }

  updateOrCreate(restaurantCode, payload) {
    return createPostRequest(`restaurants/${restaurantCode}/description`, JSON.stringify(payload), this._language);
  }
}