import React, { useState, useEffect, useCallback, createRef } from "react";
import { useNavigate } from "react-router-dom";
import DishItemForRestaurantComponent from "./DishItemForRestaurantComponent";
import useGet from "../../hooks/useGet";
import { Loader } from 'rsuite';

function DishItemsListPerRestaurantMenuComponent({ OpenModal, GetItemsRefs, dishes}) {
  const [refs, setRefs] = useState([]);
  
  useEffect(() => {
    let refds = Object.keys(dishes ?? {}).reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setRefs(refds);
  }, [dishes]);

  useEffect(() => {
    if(Object.keys(refs).length > 0 && !!refs[Object.keys(refs)[0]].current){
      GetItemsRefs(refs);
    }
  }, [refs]);

  if (!dishes) {
    return <div><Loader size="lg" className='dropzone-icon' /></div>;
  }

  return (
    <div className="padding-left-md padding-right-md">
      {Object.keys(dishes).length > 0 ?
        Object.keys(dishes).map((dish) => (
          <div key={dish} ref={refs[dish]}>
            {Object.keys(dishes).length > 1 && <div className="restaurant-menu-dish-type">{dish}</div>}
            <div>
              {dishes[dish].map((item, index) => (
                <DishItemForRestaurantComponent
                  key={item.id}
                  OpenModal={OpenModal}
                  dish={item}
                />
              ))}
            </div>
          </div>
        )) : ''}
    </div>
  );
}

export default DishItemsListPerRestaurantMenuComponent;
