import { Toggle } from "rsuite";
import React from "react";

function ToggleFilterComponent({ SetFilterValue, value }) {
  return (
    <span className="right-float">
      <Toggle checked={value} onChange={SetFilterValue} />
    </span>
  );
}

export default ToggleFilterComponent;
