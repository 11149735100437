import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useI18nContext } from '../../i18n/context/context';
import { ApiLibrary } from '../../helpers/api/ApiLibrary';

const getInitialFilters = () => ({
  types: [],
  availableAt: []
});

export const useRestaurantsFilter = (initialFilters = getInitialFilters()) => {
  const { language } = useI18nContext();
  const [filters, setFilters] = useState(initialFilters);
  const [filtersConfig, setFiltersConfig] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await new ApiLibrary(language).restaurants.general.getFilters();

      if (!response.data) {
        return;
      }

      setFiltersConfig(response.data);
    }

    fetchData();
  }, [language]);

  const isFilterActive = useMemo(() => !!(
    filters.types.length || filters.availableAt
  ), [filters]);

  const handleFiltersChange = useCallback((filter) => {
    setFilters({
      ...filters,
      ...filter
    })
  }, [filters]);

  const resetFilters = () => {
    setFilters(getInitialFilters());
  }

  return {
    filters,
    isFilterActive,
    filtersConfig,
    setFilters: handleFiltersChange,
    resetFilters
  };
};
