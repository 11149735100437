import _ from 'lodash';
import { useState } from "react";

export const useDishesSearch = () => {
  const [searchBy, setSearchBy] = useState(null);

  const search = (items, search) => {
    return items?.filter(item => item.dishName.toLowerCase().includes(search?.toLowerCase()))
  };

  return {
    search,
    searchBy,
    setSearchBy
  };
};
