import PropTypes from 'prop-types';
import { getFormattedCurrency } from '../../../helpers/formatting';

const Money = ({value, currency}) => (
  <div>
    <span className="padding-right-xl">{`${Number(value)?.toFixed(2)} ${getFormattedCurrency(currency)}`}</span>
  </div>
);

const DishPrices = ({variants, prices, className}) => {
  return (
    <div className={className} style={{flexDirection: 'column'}}>
      {variants?.map(({variant, prices}) => (
        <div key={variant} className="display-flex">
          <div>
            <span className="item-price-size-style padding-right-xl">{variant}</span>
          </div>
          <div className="display-flex">
            {prices.map(({currency, value}) => (
              <Money key={currency} currency={currency} value={value} />
            ))}
          </div>
        </div>
      ))}
      {prices?.length ? (
        <div className="display-flex">
          {prices.map(({currency, value}) => (
            <Money key={currency} currency={currency} value={value} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

DishPrices.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.number
  })),
  variants: PropTypes.arrayOf(PropTypes.shape({
    variant: PropTypes.string,
    prices: PropTypes.arrayOf(PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.number
    })),
  })),
  className: PropTypes.string
}

export default DishPrices;
