import React, { useEffect, useState } from "react";
import { FaPlus  } from "react-icons/fa";
import { FaXmark, FaPencil } from "react-icons/fa6";
import { Radio } from 'rsuite';
import MenuItemComponent from "./MenuItemComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import MenuEditComponent from "./MenuEditComponent";
import { useI18nContext } from "../../../i18n/context/context";

function MenusListComponent({ items, selectedItemName, setSelectItem, addNewManu, menuChangeCallback }) {
  const { translate, language } = useI18nContext();
  const [isNewItem, setIsNewItem] = useState(false)
  const [isEditItem, setIsEditItem] = useState(false)
  const [editItemOldValue, setEditItemOldValue] = useState("")
  const [changeCounter, setChangeCounter] = useState(1)

  const selectedItem = {
    name: translate("default"),
    isActive: true
  }
  
  const setActiveMenu = (item) => {
    item.isActive = true;
    items.filter(x => x.name != item.name).forEach(x => {
      x.isActive = false;
    });
    setChangeCounter(changeCounter+1)
  }

  const edit = (oldValue) => {
    setEditItemOldValue(oldValue)
    setIsEditItem(true)
  }

  const setEditedValue = (newVal) => {
    items.filter(x => x.name == editItemOldValue).forEach(x => {
      x.name = newVal;
    });

    if(selectedItemName == editItemOldValue){
      menuChangeCallback(newVal)
    }
    
    setEditItemOldValue("")
  }

  const removeItem = () => {
    let idx = items.map(x => x.name).indexOf(editItemOldValue)
    items.splice(idx, 1);
    setEditItemOldValue("")
    menuChangeCallback()
  }

  return (
    <div className="menu-edit-menus">
      <div className={`cursor-pointer menus-single-item menus-item-selected `}>
            <div className={`menus-single-item-name menus-item-active `} >
              {selectedItem.name}
            </div>
            {/* <div className="menus-item-config-active">
              {!item.isActive && <span className="menus-item-active-button" >{translate("active")}</span>}
            </div>
            <div className="menus-item-config-edit">
              <span className="edit-icon-butoon" onClick={(e) => {e.stopPropagation(); edit(item.name)}} >
                <FaPencil size={20} />
              </span>
            </div>      */}
          </div>
      
      {/* {!!items &&
        items.map((item, idx) => (
          <div key={idx} className={`cursor-pointer menus-item ${item.name == selectedItemName ? "menus-item-selected" : ""}`} onClick={(e) => setSelectItem(item)}>
            <div className={`menus-item-name ${item.isActive ? "menus-item-active" : ""}`} >
              {item.name}
            </div>
            <div className="menus-item-config-active" onClick={(e) => {e.stopPropagation(); setActiveMenu(item)}}>
              {!item.isActive && <span className="menus-item-active-button" >{translate("active")}</span>}
            </div>
            <div className="menus-item-config-edit">
              <span className="edit-icon-butoon" onClick={(e) => {e.stopPropagation(); edit(item.name)}} >
                <FaPencil size={20} />
              </span>
            </div>     
          </div>
        ))
      }

      {isNewItem &&
        <div>
          <ModalEditComponent setOpen={setIsNewItem} validModel={items.map(x => x.name)} model={""} hideDeleteButton setModelChanges={addNewManu} hideLanguages >
            <MenuEditComponent />
          </ModalEditComponent>
        </div>
      }

      {isEditItem &&
        <div>
          <ModalEditComponent setOpen={setIsEditItem} model={editItemOldValue} validModel={items.map(x => x.name).filter(x => x != editItemOldValue)} hideDeleteButton={items.length == 1} setModelChanges={setEditedValue} deleteItem={removeItem} hideLanguages >
            <MenuEditComponent />
          </ModalEditComponent>
        </div>
      }
      <div className="add-item-menus-container" onClick={() => setIsNewItem(true)} >
        <FaPlus size={40} className="add-item-admin"/>
      </div> */}
    </div>
  );
}

export default MenusListComponent;
