import { createContext, useContext } from 'react';

export const AdminApiContext = createContext({
  state: {
    cards: [],
    selectedCard: undefined,
    isLoading: false
  },
  fetchCards: () => {}
});

export const useAdminApiContext = () => useContext(AdminApiContext);