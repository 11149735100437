/* eslint-disable no-unused-vars */
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import LanguageComponent from '../components/base/top/LanguageComponent';
import RestaurantDemoItemsListComponent from '../components/restaurant/RestaurantDemoItemsListComponent';
import NavBarScrollComponent from '../components/base/head/NavBarScrollComponent';
import LogoBackComponent from '../components/base/top/LogoBackComponent';
import MapComponent from '../components/base/right/MapComponent';
import { useCookies } from 'react-cookie';
import datas from '../datas/restaurants.json';
import PropTypes from 'prop-types';
import { useI18nContext } from '../i18n/context/context';

function HomePageOld({ OpenModal, setShowGoTopButton }) {
  const [activeTab, SetActiveTab] = useState('maps');
  const [restaurant, SetRestaurant] = useState(null);
  const [search, SetSearch] = useState('');
  const [tabs, setTabs] = useState([
    'maps', 'list'
  ]);
  const [cookies, removeCookie] = useCookies(['SEFOO_SELECTED_RESTAURANT']);

  useEffect(() => {
    const selectedRestaurant = cookies.SEFOO_SELECTED_RESTAURANT;
    if (selectedRestaurant) {
      SetRestaurant(selectedRestaurant);
      removeCookie('SEFOO_SELECTED_RESTAURANT');
    }
    setShowGoTopButton(true);
  }, []);

  return (
    <div>
      <div id="header">
        <div className="col-12 margin-bottom-sm header-flex-style main-header-style">

          <div className="margin-top-sx">
            <LogoBackComponent />
          </div>
          <div>
            <LanguageComponent />
          </div>
        </div>
        { !!tabs &&
          <div className="col-12 display-flex fix-margin-under">
            <div className="" id="leftSide">
              <div className="fake-field-class"></div>
            </div>
            <div className="center-header-style home-header-center-style">
              <NavBarScrollComponent
                items={tabs}
                setSelectedMenuItem={SetActiveTab}
              />
            </div>
          </div>
        }

      </div>

      <div className="col-12">
        <div className="" id="centerSide">
          <div>
            {activeTab === 'list' && (
              <div>
                <RestaurantDemoItemsListComponent
                  SetRestaurant={SetRestaurant}
                  datas={datas}
                />
              </div>
            )}
            {activeTab === 'maps' && (
              <div>
                <MapComponent
                  SetRestaurant={SetRestaurant}
                  restaurants={datas}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

HomePageOld.propTypes = {
  OpenModal: PropTypes.func.isRequired,
  setShowGoTopButton: PropTypes.func.isRequired
};

export default HomePageOld;
