/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Button } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import SelectDirectiveComponent from "../../directives/SelectDirectiveComponent";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import ColorSelectorComponent from "../../components/admin/ColorSelectorComponent";


function Configuration({ Restaurant, handleChange }) {
  const { translate, language, languageList } = useI18nContext();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(null);
  const colors = [{value: "sefoo", text: <ColorSelectorComponent color="#3498ff" text={translate("sefoo")} />},
    {value: "blue", text: <ColorSelectorComponent color="blue" text={translate("blue")} />},
    {value: "green", text: <ColorSelectorComponent color="green" text={translate("green")} />},
    {value: "yellow", text: <ColorSelectorComponent color="yellow" text={translate("yellow")} />},
    {value: "orange", text: <ColorSelectorComponent color="orange" text={translate("orange")} />},
    {value: "red", text: <ColorSelectorComponent color="red" text={translate("red")} />},
    {value: "brown", text: <ColorSelectorComponent color="brown" text={translate("brown")} />},
  ]

  useEffect(() => {
    if(Restaurant){
      let thisModel = {...Restaurant};
      thisModel.defaultLang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == thisModel.defaultLang)[0].value
      setModel(thisModel);
    }
  }, [Restaurant])

  const handleSave = async () => {
    setIsLoading(true);
    await new ApiLibrary().restaurants.general.update(Restaurant.id, model)
    handleChange(model);
    setIsLoading(false);
    let root = document.querySelector(':root');
    if(!model.color || model.color != "sefoo"){
      root.style.setProperty('--sefooColor', model.color);
      root.style.setProperty('--secondSefooColor', model.color);
    }
    else{
      root.style.setProperty('--sefooColor', "#3498ff");
      root.style.setProperty('--secondSefooColor', "#1675e0");
    }
  }


  return (
    <>
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="admin-header-underline" />
      {!!model && 
        <div className="admin-basic-data-container">
          <div className="admin-basic-data-inside-container margin-top-xl">
            <div className="admin-basic-edit-item-field-text font-xl">
              <SelectDirectiveComponent 
                  placeholder={translate("defaultLanguage")}
                  options={languageList.map(x => ({value: x.value, text: x.label}))}
                  selectedOption={languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == model.defaultLang)[0]}
                  clearable={false}
                  onChange={(val) => {
                    let thisModel = {...model};
                    let lang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == val)[0]
                    thisModel.defaultLang = lang.value;
                    setModel(thisModel);
                  }}/>
            </div>
            <div className="admin-basic-edit-item-field-text font-xl  margin-top-xl">
              <SelectDirectiveComponent 
                  options={colors}
                  selectedOption={colors.filter(x => x.value == model.color)[0]}
                  clearable={false}
                  onChange={(val) => {
                    let thisModel = {...model};
                    thisModel.color = val;
                    setModel(thisModel);
                  }}/>
            </div>
          </div>
          <div className='save-button-container'>
            <Button
              color="green"
              appearance="primary"
              onClick={() => handleSave()}>
              {translate('save')}
            </Button>
          </div>
        </div>
      }
    </>
  );
}

export default Configuration;
