import React, { useCallback } from "react";
import InputDirectiveComponent from "../../../../directives/InputDirectiveComponent";
import { useI18nContext } from '../../../../i18n/context/context';

function PriceMaxFilterComponent({ SetFilterValue, data, value }) {
  const { translate } = useI18nContext();

  const abortMax = (val) => {
    SetFilterValue(val);
  }

  const ClearMaxPrice = useCallback(() => {
    SetFilterValue(null);
  }, [])

  return (
    <div className="price-input-style">
      {!!data.minPrice.visible && 
        <InputDirectiveComponent
          defValue={value}
          placeholder={translate("to")}
          SetValue={SetFilterValue}
          type="number" 
          focusOutCallback={abortMax}
          clearCallback={ClearMaxPrice}
        />
      }
    </div>
  );
}

export default PriceMaxFilterComponent;