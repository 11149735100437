function FilterElementComponent({ label, component, additionalComponent, selected }) {
  return (
    <div className="margin-bottom-mx padding-left-xl">
      <div className={`label padding-bottom-sx ${selected ? "selected-label" : ""}`}>
        <span>
          {label}
        </span>
        {!!additionalComponent && 
          <span className="padding-left-sx">
            {additionalComponent}
          </span>}
      </div>
      <div className="col-11 filter-min-width">{component}</div>
    </div>
  );
}

export default FilterElementComponent;
