import "./TooglerDirectiveStyles.css";

function TooglerDirectiveComponent({items, value, setValue, isSelectedItemColor}){

    return (
        <div className="toogler-container">
            {!!items &&
                items?.map((item, idx) => (
                    <div key={idx} onClick={() => setValue(item.value)} className={`toogler-item ${item.value == value ? (!!isSelectedItemColor ? "toogler-item-selected-color" : "toogler-item-selected") : ""} ${idx == 0 ? "toogler-item-left" : ""}`}>
                        {item.text}
                    </div>
                ))
            }
        </div>
    )
}

export default TooglerDirectiveComponent;