import { useState } from "react";

export const useRestaurantsSearch = () => {
  const [searchBy, setSearchBy] = useState('');

  return {
    searchBy,
    setSearchBy
  };
};
