import "../../css/restaurant.css";
import React, { useMemo } from "react";
import MapStaticComponent from "../base/right/MapStaticComponent";
import { useOpeningHours } from '../../hooks/useOpeningHours';
import { getFormattedAddress } from '../../helpers/formatting';
import { MdFullscreen } from "react-icons/md";
import { useI18nContext } from '../../i18n/context/context';

function RestaurantTabComponent({ restaurant, OpenModal }) {
  const { translate } = useI18nContext();
  const openingHours = useOpeningHours(restaurant);

  const getHeaderPictures = useMemo(() => {
    if(!restaurant || !restaurant.mainPhotoUrl)
      return (<></>)
    return (
      <img src={restaurant.mainPhotoUrl} className="restaurant-selected-backgruond-picture" />
    )
  }, [restaurant])

  const getLogoPictures = useMemo(() => {
    if(!restaurant || !restaurant.logoPhotoUrl)
      return (<></>)
    return (
      <div className="restaurant-picture-container">
        <img src={restaurant.logoPhotoUrl} className="restaurant-picture" />
      </div>
    )
  }, [restaurant])

  const getQrCodePictures = useMemo(() => {
    if(!restaurant || !restaurant.qrPhoto)
      return (<></>)
    return (
      <div className="restaurant-qr-container">
        <div className="restaurant-qr-picture-container">
          <MdFullscreen className="qr-full-screen-icon" style={{pointerEvents: 'none'}} />
          <img src={restaurant.qrPhoto} className="restaurant-picture cursor-pointer" onClick={() => OpenModal(restaurant.qrPhoto)} />
        </div>
      </div>
    )
  }, [restaurant])

  return (
    <div>
      <div className="adding-right-mx">
        <div className="">
          {getHeaderPictures}
        </div>
        <div className="display-flex">
          {getLogoPictures}
          <div className="restaurant-name-cointener">
            <div className="restaurant-name-style">
              {restaurant.restaurantName}
            </div>
          </div>
        </div>
        <div className="">
          {getQrCodePictures}
          <div className="restaurant-info-details-cointener padding-bottom-sm">
            <div className="restaurant-description-style">
              {!!restaurant.restaurantType && (
                <span className="restaurant-label">
                  {translate("restaurantType")}
                </span>
              )}
              {!!restaurant.restaurantType && (
                <span className="restaurant-info-value">{restaurant.restaurantType}</span>
              )}
            </div>
            <div className="restaurant-description-style">
              {!!restaurant.address && (
                <span className="restaurant-label">{translate("address")}</span>
              )}
              {!!restaurant.address && (
                <span className="restaurant-info-value">
                  {getFormattedAddress(restaurant.address)}
                  , {restaurant.address.city}
                </span>
              )}
            </div>
            <div className="restaurant-description-style">
              {!!restaurant.phones && restaurant.phones.length > 0 && (
                <span className="restaurant-label">{translate("phone")}</span>
              )}
              {!!restaurant.phones && restaurant.phones.length > 0 && <span className="restaurant-info-value">{restaurant.phones.map((item, index) => (<span key={index}>{item}{index < restaurant.phones.length-1 && ","} </span>))}</span>}
            </div>
            <div className="restaurant-description-style">
              {!!restaurant.mail && (
                <span className="restaurant-label">{translate("mail")}</span>
              )}
              {!!restaurant.mail && <span className="restaurant-info-value">{restaurant.mail}</span>}
            </div>
            <div className="restaurant-description-style">
              {!!restaurant.restaurantUrls && restaurant.restaurantUrls.length > 0 && (
                <>
                  <span className="restaurant-label">
                    {translate("restaurantUrl")}
                  </span>
                  {restaurant.restaurantUrls.map((url) => (
                    <span key={url} className="restaurant-info-value">
                      <a href={url}>{url}</a>
                    </span>
                  ))}
                </>
              )}
            </div>
            <div className="restaurant-description-style">
              {!!openingHours.hours && openingHours.hours.length > 0 && (
                <span className="restaurant-label">
                  {translate("openingHours")}
                </span>
              )}
              {!!openingHours.hours && (
                <>
                  <div className="opening-days"> 
                  {  
                    openingHours.hours.map((item, index) => 
                    (
                      <div key={index}> {translate(item.days[0])} {item.days.length == 2 && ", "} {item.days.length > 2 && " - "} {item.days.length > 1 && translate(item.days[item.days.length-1])}</div>
                    ))
                  }
                  </div>
                  <div> 
                  {  
                    openingHours.hours.map((item, index) => 
                    (
                      <div key={index}>{item.time}</div>
                    ))
                  }
                  </div>
                </>
              )}
            </div>
            {!!restaurant.location &&
              <div className="margin-top-mx">
                <MapStaticComponent positions={restaurant.location} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantTabComponent;
