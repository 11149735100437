import { useState, useMemo } from "react";
import { FaSortAlphaDown, FaSortAlphaUpAlt, FaSortNumericDown, FaSortNumericUpAlt, FaSortAmountDown } from 'react-icons/fa';
import useSortHelper from '../useSortHelper';
import { useI18nContext } from '../../i18n/context/context';

export const DishesSortByType = {
  GROUP_SORT: 'groupSort',
  NAME_ASC: 'nameAsc',
  NAME_DESC: 'nameDesc',
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc'
}

const SortOrder = {
  ASC: 'asc',
  DESC: 'desc'
}

const sortByPrice = (items, currency, sortOrder = SortOrder.ASC) => {
  return [...items].sort((itemA, itemB) => {
    if(sortOrder === SortOrder.ASC){
      const priceA = itemA.priceVariants.length > 0 ? Math.min(...itemA.priceVariants.map(x => Math.min(...x.prices.map(y => y.value)))) : Math.min(...itemA.prices.map(x => x.value)) ?? 0
      const priceB = itemB.priceVariants.length > 0 ? Math.min(...itemB.priceVariants.map(x => Math.min(...x.prices.map(y => y.value)))) : Math.min(...itemB.prices.map(x => x.value)) ?? 0
      return priceA - priceB;
    }
    else{
      const priceA = itemA.priceVariants.length > 0 ? Math.max(...itemA.priceVariants.map(x => Math.max(...x.prices.map(y => y.value)))) : Math.max(...itemA.prices.map(x => x.value)) ?? 0
      const priceB = itemB.priceVariants.length > 0 ? Math.max(...itemB.priceVariants.map(x => Math.max(...x.prices.map(y => y.value)))) : Math.max(...itemB.prices.map(x => x.value)) ?? 0
      return priceB - priceA;
    }
  })
}

export const useDishesSort = (initialSortBy = DishesSortByType.GROUP_SORT) => {
  const { translate } = useI18nContext();
  const sortByOptions = useMemo(() => ({
    [DishesSortByType.GROUP_SORT]: {
      key: DishesSortByType.GROUP_SORT,
      icon: <FaSortAmountDown size={15} />,
      title: translate("groupSort")
    },
    [DishesSortByType.NAME_ASC]: {
      key: DishesSortByType.NAME_ASC,
      icon: <FaSortAlphaDown size={15} />,
      title: translate("nameAsc")
    },
    [DishesSortByType.NAME_DESC]: {
      key: DishesSortByType.NAME_DESC,
      icon: <FaSortAlphaUpAlt size={15} />,
      title: translate("nameDesc")
    },
    [DishesSortByType.PRICE_ASC]: {
      key: DishesSortByType.PRICE_ASC,
      icon: <FaSortNumericDown size={15} />,
      title: translate("priceAsc")
    },
    [DishesSortByType.PRICE_DESC]: {
      key: DishesSortByType.PRICE_DESC,
      icon: <FaSortNumericUpAlt size={15} />,
      title: translate("priceDesc")
    },
  }), [translate]);
  const [sortBy, setSortBy] = useState(initialSortBy);

  const { SortAlphabetArray } = useSortHelper();

  const sort = (items, sortBy, filters) => {
    const sortFunctionMapper = {
      [DishesSortByType.NAME_ASC]: () => SortAlphabetArray(items, "asc", "dishName"),
      [DishesSortByType.NAME_DESC]: () => SortAlphabetArray(items, "desc", "dishName"),
      [DishesSortByType.PRICE_ASC]: () => sortByPrice(items, filters.currency, SortOrder.ASC),
      [DishesSortByType.PRICE_DESC]: () => sortByPrice(items, filters.currency, SortOrder.DESC),
    }

    return sortFunctionMapper[sortBy]?.() ?? items;
  };

  return {
    sort,
    sortBy,
    setSortBy,
    sortByOptions
  };
};
