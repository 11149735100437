import React, { useState, useEffect, useCallback, createRef } from "react";
import { useNavigate } from "react-router-dom";
import HomeDishItemForRestaurantComponent from "./HomeDishItemForRestaurantComponent";
import useGet from "../../hooks/useGet";

function HomeDishItemsListPerRestaurantMenuComponent({ OpenModal, GetItemsRefs, dishes }) {
  const [refs, setRefs] = useState([]);
  
  useEffect(() => {
    let refds = Object.keys(dishes).reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setRefs(refds);
  }, [dishes]);

  useEffect(() => {
    if(Object.keys(refs).length > 0 && !!refs[Object.keys(refs)[0]].current){
      GetItemsRefs(refs);
    }
  }, [refs]);

  return (
    <div className="padding-left-sx padding-right-sx">
      {!!dishes &&
        Object.keys(dishes).map((dish) => (
          <div key={dish} ref={refs[dish]}>
            <div className="hm-restaurant-menu-dish-type">{dish}</div>
            <div>
              {dishes[dish].map((item, index) => (
                <HomeDishItemForRestaurantComponent
                  key={index}
                  OpenModal={OpenModal}
                  dish={item}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default HomeDishItemsListPerRestaurantMenuComponent;
