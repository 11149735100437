import SelectDirectiveComponent from "../../../../directives/SelectDirectiveComponent";
import React from "react";
import { useI18nContext } from '../../../../i18n/context/context';

function TypeFilterComponent({ SetFilterValue, data, value }) {
  const { translate } = useI18nContext();
  return (
    <span className="">
      {data && (
        <SelectDirectiveComponent
          placeholder={translate("select")}
          options={data}
          onChange={SetFilterValue}
          selectedOption={data?.find((option) => value === option.value)}/>
      )}
    </span>
  );
}

export default TypeFilterComponent;
