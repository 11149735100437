const API_URL = process.env.REACT_APP_APIURL;

const getDefaultHeaders = ({language = 'pl', contentType = 'application/json'}) => {
  return {
    'Accept-Language': language,
    ...(contentType ? {'Content-Type': contentType} : {}),
  };
};

export const createGetRequest = (url, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'GET',
  
  }).then((response) => response.json())
);

export const createPostRequest = (url, body, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'POST',
    body,
  }).then((response) => response.json())
);

export const createPutRequest = (url, body, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'PUT',
    body,
  }).then((response) => response.json())
);

export const createDeleteRequest = (url, language, contentType) => (
  fetch(API_URL.concat(url), {
    headers: getDefaultHeaders({language, contentType}),
    method: 'DELETE',
  }).then((response) => response.json())
);
