import "../../css/restaurant.css";
import { GoDotFill } from "react-icons/go";

function HomeRestaurantHeaderComponent({ restaurant }) {
  return (
    <div>
        <div className="">
          <img
            src={restaurant.mainPhotoUrl}
            className="home-restaurant-backgruond-picture"
          />
        </div>
        <div className="display-flex">
          <div className="home-restaurant-picture-container">
            <img src={restaurant.logoPhotoUrl} className="home-restaurant-picture" />
          </div>
          <div className="hm-restaurant-name-cointener">
            <div className="hm-restaurant-name-style">
              {restaurant.restaurantName}
            </div>
          </div>
        </div>
    </div>
  );
}

export default HomeRestaurantHeaderComponent;
