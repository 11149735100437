import PropTypes from 'prop-types';
import InputDirectiveComponent from '../../../directives/InputDirectiveComponent';

function SearchComponent ({ SetSearchFunction }) {
  return (
    <div>
      <div className="padding-left-md padding-right-md">
        <InputDirectiveComponent
          SetValue={(val) => SetSearchFunction(val)}
          isSearch={true}
          clearCallback={() => SetSearchFunction('')}
        />
      </div>
    </div>
  );
}

SearchComponent.propTypes = {
  SetSearchFunction: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired
};

export default SearchComponent;
