import React, { useEffect, useState } from "react";
import { Input } from 'rsuite';
import { useI18nContext } from "../../../i18n/context/context";

function MenuCardEditComponent({ model, setChanges, setCanSave, validModel }) {
  const { translate } = useI18nContext();
    
  const [myModel, setMyModel] = useState(null)
  const [myCanSave, setMyCanSave] = useState(false)

  useEffect(() => {
    if(!!model){
      setMyModel(model)
      if(!!model){
        setMyCanSave(true)
      }
      else{
        setMyCanSave(false)
      }
    }
    else{
      setMyModel("")
    }
  }, [model])

  useEffect(() => {
    setCanSave(myCanSave)
  }, [myCanSave])


  const changeValue = (val) => {
    setChanges(val);
    setMyModel(val)
    setMyCanSave(!!val && (!validModel || !validModel.map(x => x.name).includes(val)));
  }

  return (
    <div className="edit-item-style">
      <div className="edit-item-field">
        <Input className={`edit-item-field-text ${!myCanSave ? "edit-input-error" : ""}`}
          defaultValue={model}
          onChange={(val, e) => changeValue(val)}
        />
      </div>
    </div>
  );
}

export default MenuCardEditComponent;
