import vegetarian from "../../../assets/images/vegetarian-icon.png";
import vegan from "../../../assets/images/vegan-icon.png";
import lactoseFree from "../../../assets/images/lactose-free-icon.png";
import glutenFree from "../../../assets/images/gluten-free.png";
import React, { useEffect, useMemo, useState } from "react";
import IconWithTooltipComponent from "../../../directives/IconWithTooltipComponent";
import { FaPencil } from "react-icons/fa6";
import { useI18nContext } from "../../../i18n/context/context";
import { Toggle } from "rsuite";
import VideoPlayerComponent from "../../base/VideoPlayerComponent";
import CloudinaryImage from "../../CloudinaryImage";
import DishPrices from '../../dish/components/DishPrices';
import DishItemPriceComponent from "../../dish/components/DishItemPriceComponent";

function MenuItemComponent({ dish, editItem, copy }) {
  const { translate } = useI18nContext();
  const [changeCounter, setChangeCounter] = useState(1)
  const getPrice = useMemo(() => {
    if(!dish || !dish.price)
      return (<></>)
    return (
      <div className="display-flex">
        {dish.price.map((x, idx) => (
          <div key={idx} className="item-price-style padding-right-xl">
            {Object.values(x)[0]} {Object.keys(x)[0]}
          </div>
        ))}
      </div>
    )
}, [dish]);

  useEffect(() => {
    setChangeCounter(changeCounter+1)
  }, [dish])


  const getImage = useMemo(() => {
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (

        <div className="">
        {dish.urlFirstPhoto.includes('cloudinary') ? (
          <CloudinaryImage
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            cropMode="fill"
            width={150}
            height={150}
            className="item-img-style"
          />
        ) : (
          <img
            alt={dish.dishName}
            src={dish.urlFirstPhoto}
            className="item-img-style"
          />
        )}
      </div>
      )
    }
    else{
      return (
        <div className='admin-dish-picture-container'>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={dish.urlFirstPhoto}
              className="admin-dish-video-style"
              autoplay={true}
              playIfFocus={true}
              currentTime={0}
            />
          </div>
        </div>
      )
    }
  }, [dish])

  return (
    <div>
      <div className="admin-item-style">
        {!!dish.urlFirstPhoto &&
          <div className="item-picture-style">
            {getImage}
          </div>
        }
        <div className="item-body">
          <div className="item-header-style">
            <div className="item-header-inside">
              {/* <span className="margin-right-md edit-icon-butoon">
                <FaRegCopy size={30} onClick={() => copy(dish)} />
              </span> */}
              <span className="margin-right-md edit-icon-butoon">
                <FaPencil size={30} onClick={() => editItem()} />
              </span>
              {/* <span className="margin-right-md">
                {!!changeCounter && <Toggle defaultChecked={dish.isActive} onChange={(val) => dish.isActive = val} />}
              </span> */}
            </div>
          </div>
          <div className="admin-item-details-style">
            <div className="item-details-inside-style">
              <div className="item-name-style margin-bottom-sm">{dish.dishName}</div>
              {!!dish.dishType && 
                <div className="item-element-style item-description-style">
                <span className="item-label">{translate("type")}</span>
                  <span>{dish.dishType}</span>
                </div>
              }
              {!!dish.description && 
                <div className="item-element-style item-description-style">
                <span className="item-label">{translate("description")}</span>
                  <span>{dish.description}</span>
                </div>
              }
              {!!dish.dishItems && dish.dishItems.length > 0 &&
              <div className="item-element-style">
                <span className="item-label">{translate("elements")}</span>
                  <span className="item-description-style">
                {dish.dishItems.map(({text}, index) => (
                  <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                  ))}
                  </span>
                </div>
              }
              {!!dish.allergens && dish.allergens.length > 0 && 
                <div className="item-element-style">
                  <span className="item-label">{translate("allergens")}</span>
                  <span className="item-description-style">
                    {dish.allergens.map((item, index) => (
                      <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                    ))}
                  </span>
                </div>
              }
              { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
                <div className="item-element-style">
                  {dish.isVegetarian &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                    </span>
                  }
                  {dish.isVegan &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                    </span>
                  }
                  {dish.isLactoseFree &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                    </span>
                  }
                  {dish.isGlutenFree &&
                    <span className="margin-right-sm">
                      <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                    </span>
                  }
                </div>
              }
              {/* <DishPrices variants={dish.priceVariants} prices={dish.prices} /> */}

              <DishItemPriceComponent prices={dish.prices} priceVariants={dish.priceVariants} />
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItemComponent;
