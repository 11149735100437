import React, { useCallback, useEffect, useState }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import TimeInput from "react-time-input";

function AdminBasicOpenCloseComponent({ restaurant }) {
  const { translate, language } = useI18nContext();

  const onFocusHandler = (event) => {
    console.log("hello there you entered :  my name is ", event.target.name);
}

const onBlurHandler = (event) => {
    console.log("you left ");
}

const onTimeChangeHandler = (val) => {
    if (val.length === 5) {
        // do something with this value
    }
}

  return (
    <div>
        <TimeInput
           name="example"
           initTime='11:12'
           className='s-input -time'
           mountFocus='true'
           onTimeChange={onTimeChangeHandler}
           onFocusHandler={onFocusHandler}
           onBlurHandler={onBlurHandler}
       />
    </div>
    
  );
}

export default AdminBasicOpenCloseComponent;
