import { BaseApi } from '../shared/baseApi';
import { createGetRequest, createPostRequest } from '../shared/fetch';

export class PostersApi extends BaseApi {
  get(restaurantId) {
    return createGetRequest(`restaurants/${restaurantId}/poster`, this._language);
  }

  updateOrCreate(restaurantId, payload) {
    return createPostRequest(`restaurants/${restaurantId}/poster`, JSON.stringify(payload), this._language);
  }
}