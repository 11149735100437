import { useCallback, useEffect, useState } from "react";
import NavBarCardAdminComponent from "./NavBarCardAdminComponent";
import MenusListComponent from "./MenusListComponent";
import CopyItemComponent from "./CopyItemComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { FaRegCopy } from "react-icons/fa6";
import { useI18nContext } from "../../../i18n/context/context";

function AdminMenuComponent({restaurantMenus, restaurantId, setMenu}) {
    const { translate, language } = useI18nContext();
    const [selectedItem, setSelectedItem] = useState(restaurantMenus[0])
    const [changeCounter, setChangeCounter] = useState(1)
    const [isCopyItem, setIsCopyItem] = useState(false)
    const [itemToCopy, setItemToCopy] = useState(false)

    const menuChangeCallback = (newVal) => {
        if(restaurantMenus.filter(x => x.name == selectedItem.name).length == 0){
            setSelectedItem(restaurantMenus[0])
        }

        if(!!newVal){
            setSelectedItem(restaurantMenus.filter(x => x.name == newVal)[0])
            setChangeCounter(changeCounter+1)
        }

        if(restaurantMenus.filter(x => x.isActive).length == 0){
            restaurantMenus[0].isActive = true
        }
    }

    const addNewManu = (name) => {
        let newObj = {
            isActive: false,
            name: name,
            cards: [{
                id: null,
                code: "specialEmptyCard",
                language: language,
                name: translate("specialEmptyCard"),
                filters: {
                    dishType: false,
                    size: false,
                    price: false,
                    includedItems: false,
                    excludedItems: false,
                    allergens: false,
                    isLactoseFree: false,
                    isGlutenFree: false,
                    isVegan: false,
                    isVegetarian: false
                },
                groups: [{
                    id: 0,
                    code: "specialEmptyGroup",
                    language: language,
                    name: translate("specialEmptyGroup"),
                    items: []
                }]
            }]
        }

        restaurantMenus.push(newObj)
        setSelectedItem(newObj)
    }

    const copy = (item) => {
        setItemToCopy(item)
        setIsCopyItem(true)
    }

    const copySaveChanged = (model) => {

    }

    return(
        <>
            <div className="display-flex">
                <div className="menu-edit-body">
                    {!!selectedItem && <NavBarCardAdminComponent restaurantMenu={selectedItem} restaurantId={restaurantId} copy={copy} />}
                </div>
                <div className="menu-edit-menus-container">
                    {!!selectedItem && <MenusListComponent items={restaurantMenus} selectedItemName={selectedItem.name} setSelectItem={setSelectedItem} addNewManu={addNewManu} menuChangeCallback={menuChangeCallback} />}
                </div>
            </div>
            {isCopyItem &&
                <div>
                    <ModalEditComponent setOpen={setIsCopyItem} hideDeleteButton setModelChanges={copySaveChanged} hideLanguages >
                        <CopyItemComponent copyElementModel={itemToCopy} items={restaurantMenus} />
                    </ModalEditComponent>
                </div>
            }
        </>
        )
        
    //     setCards(mCards)
    //     setCardItems(mCards.map(x => x.name))
    // }

    // const editCard = (oldValue, newValue) => {
    //     if(oldValue == newValue){
    //         return;
    //     }
    //     let mCards = cards;
    //     let idx = mCards.map(x => x.name).indexOf(oldValue)
    //     mCards[idx].name = newValue
    //     setCards(mCards)
    //     setCardItems(mCards.map(x => x.name))
    // }

    // const removeCard = (val) => {
    //     let mCards = cards;
    //     let idx = mCards.map(x => x.name).indexOf(val)
    //     mCards.splice(idx, 1);
    //     if(mCards.length == 0){
    //         let newItem = {
    //             id: 0,
    //             code: "specialEmptyCard",
    //             language: language,
    //             name: translate("specialEmptyCard"),
    //             groups: [{
    //                 id: null,
    //                 code: "specialEmptyGroup",
    //                 language: language,
    //                 name: translate("specialEmptyGroup"),
    //             items: []
    //             }]
    //         }
    //         mCards.push(newItem);
    //     }
    //     setCards(mCards)
    //     setCardItems(mCards.map(x => x.name))
    // }

    // const copySaveChanged = (model) => {

    // const [groups, setGroups] = useState([])
    // const [activeGroup, SetActiveGroup] = useState(null);
    // const [dishes, setDishes] = useState([])


    // const updateCardsGroup = (groups) => {
    //     let mCards = cards;
    //     let idx =  mCards.map(x => x.name).indexOf(activeCard)
    //     mCards[idx].groups = groups
    //     setCards(mCards)
    // }

    // const generateNewGroupCode = (id) => {
    //     let mCards = cards;
    //     let idx =  mCards.map(x => x.name).indexOf(activeCard)
    //     let cardCode = mCards[idx].code
    //     return restaurantCode.concat(cardCode).concat("Group").concat(id);
    // }

    // const addGroup = (val) => {
    //     let mGroups = groups;
    //     if(mGroups.length == 1 && mGroups[0].code=="specialEmptyGroup"){
    //         mGroups[0].id = 0;
    //         mGroups[0].name = val;
    //         mGroups[0].code = generateNewGroupCode(0);
    //     }
    //     else{
    //         let newId = Math.max(...mGroups.map(x => x.id)) + 1;
    //         let newItem = {
    //                 id: newId,
    //                 code: generateNewGroupCode(newId),
    //                 language: language,
    //                 name: val,
    //                 items: []
    //             }
    //         mGroups.push(newItem);
    //     }
             
    //     setGroups(mGroups)
    //     setGroupItems(mGroups.map(x => x.name))
    //     updateCardsGroup(mGroups)
    // }

    // const editGroup = (oldValue, newValue) => {
    //     let mGroups = groups;
    //     let idx = mGroups.map(x => x.name).indexOf(oldValue)
    //     mGroups[idx].name = newValue
    //     setGroups(mGroups)
    //     setGroupItems(mGroups.map(x => x.name))
    //     updateCardsGroup(mGroups)
    // }

    // const removeGroup = (val) => {
    //     let mGroups = groups;
    //     let idx = mGroups.map(x => x.name).indexOf(val)
    //     mGroups.splice(idx, 1);
    //     if(mGroups.length == 0){
    //         let newItem = {
    //             id: 0,
    //             code: "specialEmptyGroup",
    //             language: language,
    //             name: translate("specialEmptyGroup"),
    //             items: []
    //         }
    //         mGroups.push(newItem);
    //     }
    //     setGroups(mGroups)
    //     setGroupItems(mGroups.map(x => x.name))
    //     updateCardsGroup(mGroups)
    // }

    // const openEditModal = useCallback(() =>{
    //     setModalOpen(true)
    // }, [modalOpen])

    // useEffect(() => {
    //     if(!!restaurantMenu){
    //         setCards(restaurantMenu.cards)
    //         setCardItems(restaurantMenu.cards.map(x => x.name))
    //         SetActiveCard(restaurantMenu.cards[0].name)
    //         SetActiveGroup(restaurantMenu.cards[0].groups[0].name)
    //         setGroups(restaurantMenu.cards[0].groups)
    //         setGroupItems(restaurantMenu.cards[0].groups.map(x => x.name))
    //         setDishes(restaurantMenu.cards[0].groups[0].dishes)
    //     }
    // }, [restaurantMenu])

    // const SetActiveCardFunction = useCallback((val) => {
    //     if(val == activeCard){
    //         return;
    //     }
    //     SetActiveCard(val)
    //     let idx =  cards.map(x => x.name).indexOf(val)
    //     setGroups(cards[idx].groups)
    //     SetActiveGroup(cards[idx].groups[0].name)
    //     setGroupItems(cards[idx].groups.map(x => x.name))
    //     setDishes(cards[idx].groups[0].items)
    // }, [cards, activeCard, groups, activeGroup, dishes])

    // const SetActiveGroupFunction = useCallback((val) => {
    //     if(val == activeGroup || !groups.includes(activeGroup)){
    //         return;
    //     }
    //     SetActiveGroup(val)
    //     let idx =  groups.map(x => x.name).indexOf(val)
    //     setDishes(groups[idx].items)
    // }, [cards, activeCard, groups, activeGroup, dishes])

    // const setItems = (items) => {
    //     if(!!activeCard && !!activeGroup){
    //         let idx =  restaurantMenu.cards.map(x => x.name).indexOf(activeCard)
    //         let groupIdx = restaurantMenu.cards[idx].groups.map(x => x.name).indexOf(activeGroup)
    //         restaurantMenu.cards[idx].groups[groupIdx].items = items
    //         setDishes(items)
    //     }
    // }

    // return (<>
    //     {<NavBarCardAdminComponent restaurantMenu={restaurantMenu} restaurantCode={restaurantCode} />}
    // </>)

}
export default AdminMenuComponent;
  