import './QuillEditor.css';
import cx from 'classnames';

const QuillEditor = ({quillRef, disabled}) => {
  return (
    <div className="QuillEditor">
      <div className={cx('quill-editor', {disabled})}>
        <div className="quill-editor-content">
          <div ref={quillRef} />
        </div>
      </div>
    </div>
  );
};

export default QuillEditor;
