
import React, { useEffect, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import RestaurantItemFromMapComponent from '../restaurant/RestaurantItemFromMapComponent'

const drawerBleeding = 56;

function RestaurantBottomDrawer({restaurant, onClose, OpenModal}) {
  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(!!restaurant);
  }, [restaurant])

  const mapContainer = document.querySelector('.map-dynamic-container');

  return mapContainer && <>
    <SwipeableDrawer
      hideBackdrop={true}
      container={document.body}
      anchor="bottom"
      open={open}
      onOpen={() => {}}
      onClose={onClose}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          height: `calc(${mapContainer?.getBoundingClientRect().height} * 0.5)px`,
          position: 'absolute',
          width: mapContainer?.getBoundingClientRect().width,
          left: mapContainer?.getBoundingClientRect().left
        }
      }}
    >
      {restaurant &&
        <RestaurantItemFromMapComponent
          restaurant={restaurant}
          OpenModal={OpenModal}
        />
      }
    </SwipeableDrawer>
  </>
}

export default RestaurantBottomDrawer