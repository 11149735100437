import { useCallback, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import { ImageHandler, VideoHandler } from 'quill-upload';
import 'quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { ApiLibrary } from '../../helpers/api/ApiLibrary';

export const useQuillEditor = (folder, readOnly) => {
  const { Quill, quill, quillRef } = useQuill({
    readOnly,
    modules: {
      imageHandler: {
        upload: async (file) => {
          const response = await new ApiLibrary().common.add(file, folder);

          return response.data.url;
        },
      },
      videoHandler: {
        upload: async (file) => {
          const response = await new ApiLibrary().common.add(file, folder);

          return response.data.url;
        },
      }
    },
  });

  if (Quill && !quill) {
    Quill.register("modules/imageHandler", ImageHandler);
    Quill.register("modules/videoHandler", VideoHandler);
    Quill.register('modules/imageResize', ImageResize);
    let Font = Quill.import('formats/font');
    Font.whitelist = ['inconsolata', 'roboto', 'mirza', 'arial'];
    Quill.register(Font, true);
  }

  const setContent = useCallback((content) => {
    if (quill) {
      quill.root.innerHTML = content;
    }
  }, [quill]);

  const getContent = useCallback(() => {
    return quill.root.innerHTML;
  }, [quill]);

  return {
    quillRef,
    setContent,
    getContent,
    quill
  };
}