function HomeDescriptionComponent({ items }) {
  return (
    <div className="hm-description-style">
      <div className="padding-left-sx padding-right-sx">
        {!!items && 
            items.map((item, idx) => (
                <div key={idx} className="hm-description-paragraph">
                    <div className="hm-descirption-title">
                        {item.title}
                    </div>
                    <div className="hm-descirption-body">
                        {item.body}
                    </div>
                </div>
            ))
        }
      </div>
    </div>
  );
}

export default HomeDescriptionComponent;
