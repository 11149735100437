import '../App.css';
import '../css/home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useCallback } from 'react';
import LanguageComponent from '../components/base/top/LanguageComponent';
import SearchComponent from '../components/base/center/SearchComponent';
import RestaurantDemoItemsListComponent from '../components/restaurant/RestaurantDemoItemsListComponent';
import NavBarScrollComponent from '../components/base/head/NavBarScrollComponent';
import LogoBackComponent from '../components/base/top/LogoBackComponent';
import MapComponent from '../components/base/right/MapComponent';
import FiltersHomeComponent from '../components/home/FiltersHomeComponent';
import FiltersHidingHomeComponent from '../components/home/FiltersHidingHomeComponent';
import useScreenDetector from '../hooks/useScreenDetector';
import { IconButton } from 'rsuite';
import { TbFilterSearch } from "react-icons/tb";
import { RiMenuUnfoldLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useRestaurantsFilter } from '../hooks/restaurants/useRestaurantsFilter';
import { useRestaurantsSearch } from '../hooks/restaurants/useRestaurantsSearch';
import { useI18nContext } from '../i18n/context/context';
import { ApiLibrary } from '../helpers/api/ApiLibrary';

function HomeDefPage({ OpenModal, setShowGoTopButton }) {
  const { language } = useI18nContext();
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filterButtonAppearance, setFilterButtonAppearance] = useState('default');
  const [tabs, setTabs] = useState([]);
  const [restaurants, setRestaurants] = useState(null);
  const [activeTab, SetActiveTab] = useState(null);
  const { filters, setFilters, filtersConfig } = useRestaurantsFilter();
  const { searchBy, setSearchBy } = useRestaurantsSearch();

  const { sizeScreen } = useScreenDetector();

  const fetchRestaurants = useCallback(async({ search, filters }) => {
    const response = await new ApiLibrary(language).restaurants.general.getList({ search, filters });

    if (!response.data) {
      return;
    }

    setRestaurants(response.data);

  }, [language]);

  useEffect(() => {
    setRestaurants(null);
    fetchRestaurants({ filters, search: searchBy });
  }, [filters, searchBy, language]);

  useEffect(() => {
    const mTabs = ['maps'];
    const list = document.getElementById('restaurantListRight');
    if (!!list && getComputedStyle(list).display === 'none') {
      mTabs.push('list');
    }
    setTabs(mTabs);

    if (!mTabs.includes(activeTab) || !activeTab) {
      SetActiveTab(mTabs[0]);
    }
  }, [sizeScreen]);

  useEffect(() => {
    setShowGoTopButton(true);
  }, []);

  const SetSearchFunction = useCallback(debounce((search) => {
    setSearchBy(search)
  }, 200), []);

  const handleRestaurantChange = useCallback((restaurant) => {
    if (selectedRestaurant && restaurant?.restaurantCode === selectedRestaurant.restaurantCode) {
      setSelectedRestaurant(undefined);
      return;
    }
    setSelectedRestaurant(restaurant)
  }, [selectedRestaurant])

  return (
    <div>
      <div id="header">
        <div className="col-12 margin-bottom-sm header-flex-style main-header-style">
          <div className="margin-top-sx">
            <LogoBackComponent />
          </div>
          <div>
            <LanguageComponent />
          </div>
        </div>
        {!!tabs &&
          <div className="col-12 display-flex fix-margin-under">
            <div className="left-header-style">
              <div className="fake-field-class"></div>
            </div>
            <div className="center-header-style">
              <NavBarScrollComponent
                items={tabs}
                setSelectedMenuItem={SetActiveTab}
                DefActiveTab={activeTab}
              />
            </div>
          </div>
        }
      </div>
      <div id="restaurantBody" className="col-12 main">
        <div id="restaurantStaticFilters" className="left-filter-style padding-right-md">
          <FiltersHomeComponent
            setFilterData={setFilters}
            configuration={filtersConfig}
            filters={filters}
          />
        </div>
        <div className="filter-menu-container-style">
          {filterOpen &&
            <FiltersHidingHomeComponent
              isOpen={filterOpen}
              setFilterData={setFilters}
              configuration={filtersConfig}
              filterButtonAppearance={filterButtonAppearance}
              SetFilterClose={() => setFilterOpen(false)}
            />
          }

        </div>

        <div id="restaurantCenter" className="center-side-style">
          {
            <div className="">
              <div className="header-style sticky-zero-position-style">
                <div className="display-flex">
                  <div id="hiddingFilters" className="padding-left-md">
                    <IconButton
                      circle
                      icon={<TbFilterSearch size={15}/>}
                      appearance={filterButtonAppearance}
                      onClick={() => setFilterOpen(!filterOpen)}
                    />
                  </div>
                  <div className="search-style">
                    <SearchComponent
                      SetSearchFunction={SetSearchFunction}
                      delay={0}
                    />
                  </div>
                </div>
                {activeTab === 'maps' && (
                  <div className="margin-top-sm">
                    <MapComponent
                      onSelectedRestaurantChange={handleRestaurantChange}
                      selectedRestaurant={selectedRestaurant}
                      restaurants={restaurants}
                      OpenModal={OpenModal}
                    />
                  </div>
                )}
                {activeTab === 'list' && (
                  <div className="margin-top-sm">
                    <RestaurantDemoItemsListComponent
                      onSelectedRestaurantChange={handleRestaurantChange}
                      restaurants={restaurants}
                    />
                  </div>
                )}

              </div>
            </div>
          }
        </div>
        <div id="restaurantListRight" className="right-side-style">
          <div className="sticky-zero-position-style">
            <RestaurantDemoItemsListComponent
              onSelectedRestaurantChange={handleRestaurantChange}
              selectedRestaurant={selectedRestaurant}
              restaurants={restaurants}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

HomeDefPage.propTypes = {
  OpenModal: PropTypes.func.isRequired,
  setShowGoTopButton: PropTypes.func.isRequired
};

export default HomeDefPage;
