/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import "../css/dish.css";
import "../css/global.css";
import "../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Button } from "rsuite";
import { useI18nContext } from "../i18n/context/context";
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { useAdminApiContext } from '../hooks/admin/context';
import AdminPreviewComponent from "../components/admin/AdminPreviewComponent";
import { useCookies } from 'react-cookie';
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import { useLogger } from '../hooks/logger/useLogger';
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../components/base/top/LogoBackComponent";
import MyPage from "./adminSubPages/MyPage";
import AdditionalWindows from "./adminSubPages/AdditionalWindows";
import Configuration from "./adminSubPages/Configuration";
import LanguageComponent from "../components/base/top/LanguageComponent";

function AdministratorPage({ OpenEditModal, OpenModal }) {
  const { translate, language } = useI18nContext();
  const [restaurant, setRestaurant] = useState();
  const [isPreview, setIsPreview] = useState(false)
  const { restaurantCode } = useParams();
  const navigate = useNavigate();
  const { fetchCards } = useAdminApiContext();
  const [cookies, setCookie, deleteCookie] = useCookies(['api-key']);
  const logger = useLogger();
  const [isLoading, setIsLoader] = useState(false)

  const pages = ["myPage", "additionalWindows", "configuration"]
  const [activPage, setActivePage] = useState("myPage")

  useEffect(() => {
    const verify = async () => {
      try {
        const apiKey = cookies['api-key'] ?? prompt('Insert API key');

        if(apiKey == null){
          window.location.href = window.location.origin;
          return true
        }
        
        setIsLoader(true)
        const response = await new ApiLibrary().restaurants.general.verifyApiKey(restaurantCode, apiKey);
        setIsLoader(false)

        if (!response.data) {
          return false;
        }

        if (response.data.id) {
          setCookie('api-key', apiKey, {
            maxAge: 60*60*24
          });
          setRestaurant(response.data);
          let root = document.querySelector(':root');
          if(!response.data.color || response.data.color != "sefoo"){
            root.style.setProperty('--sefooColor', response.data.color);
            root.style.setProperty('--secondSefooColor', response.data.color);
          }
          else{
            root.style.setProperty('--sefooColor', "#3498ff");
            root.style.setProperty('--secondSefooColor', "#1675e0");
          }
          return true;
        } else {
          deleteCookie('api-key');
          navigate(`/${restaurantCode}`);
        }
      } catch(error) {
        navigate(`/${restaurantCode}`);
      }
    }

    const setVerify = () => {
      verify().then((result) => {
        if(result){
          fetchCards(restaurantCode);
        }
        else{
          setVerify()
        }
      });
    }
    setVerify()
  }, [restaurantCode]);

  const onRestaurantChange = (restaurantUpdate) => {
    setRestaurant({
      ...restaurant,
      ...restaurantUpdate
    })
  };

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {isPreview &&
          <AdminPreviewComponent OpenModal={OpenModal} setPreview={setIsPreview} />
        }
        {!isPreview && !!restaurant &&
        <div>
          <div>
            <div className="col-12 margin-bottom-sm header-flex-style main-header-style">
              <div className="margin-top-sx" onClick={() => navigate(`/${restaurantCode}`)}>
                <LogoBackComponent />
              </div>
              <div className="display-flex">
              </div>
              <LanguageComponent defLang={restaurant.defaultLang} />
            </div>
          </div>
          <div className="admin-page-container">
            <div className="admin-subpages-container">
              {pages.map((page, idx) => (
                <div key={idx} className={`admin-page ${page == activPage ? "selected-admin-page" : ""}`} onClick={() => setActivePage(page)}>
                  {translate(page)}
                </div>
              ))}
            </div>
            <div className="admin-main-button">
              <Button appearance="ghost" onClick={() => setIsPreview(true)}>{translate("preview")}</Button>
            </div>
            {!!restaurant && 
              <>
                {activPage == "myPage" && <MyPage OpenEditModal={OpenEditModal} Restaurant={restaurant} />}
                {activPage == "additionalWindows" && <AdditionalWindows Restaurant={restaurant} />}
                {activPage == "configuration" && <Configuration Restaurant={restaurant} handleChange={onRestaurantChange} />}
              </>      
            }
          </div>
        </div>
        }
    </>
  )
}

export default AdministratorPage;
