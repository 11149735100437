import { CommonApi } from './common/common';
import { CardsApi } from './menu/cards';
import { CategoriesApi } from './menu/categories';
import { DishesApi } from './menu/dishes';
import { AnnouncementsApi } from './restaurant/annoucements';
import { RestaurantMediaApi } from './restaurant/media';
import { PostersApi } from './restaurant/posters';
import { RestaurantsApi } from './restaurant/restaurants';
import { BaseApi } from './shared/baseApi';
import { DescriptionApi } from './restaurant/description';

export class ApiLibrary extends BaseApi {
  constructor(language) {
    super(language);

    this.menu = {
      cards: new CardsApi(language),
      categories: new CategoriesApi(language),
      dishes: new DishesApi(language),
    };

    this.restaurants = {
      general: new RestaurantsApi(language),
      posters: new PostersApi(language),
      announcements: new AnnouncementsApi(language),
      description: new DescriptionApi(language),
      media: new RestaurantMediaApi(language),
    };

    this.common = new CommonApi(language);
  }
}