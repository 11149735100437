import "./InputDirectiveStyles.css";
import { useState, useEffect, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function InputHourDirectiveComponent({placeholder, defValue, SetValue, type, isSearch, focusInCallback, focusOutCallback, clearCallback}){
    const [isFocused, setIsFocused] = useState(false);
    const [value, setVal] = useState(!!defValue ? defValue : "");
    const [generatedId, SetGeneratedId] = useState("");
    const myRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if(!!myRef){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("inputDirective".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    // useEffect(() => {
    //     SetValue(value);
    // }, [value])

    const setFocus = useCallback(() => {
        if(!!inputRef && !value){
            inputRef.current.focus();
        }
    }, [inputRef, value])

    const focusInput = useCallback((e) => {
        setIsFocused(true);
        if(!!focusInCallback){
            focusInCallback();
        }
    }, [])

    const focusOutInput = (e) => {
        setIsFocused(false);
        if(focusOutCallback){
            focusOutCallback(value);
        }
    }

    const clieckLeft = () => {

      }
  
      const clieckRight = () => {

      }
  


    const clear = useCallback((e) =>{
        setVal("");
        if(!!clearCallback){
            clearCallback();
        }
        e.stopPropagation();
    },[value])

    return (
        <div id={generatedId} ref={myRef} className="id-main">
            <div className={`id-input-body ${!!isFocused ? "id-focused" : ""} ${!!value ? "id-not-empty" : ""}`}>
                <FaChevronLeft size={20} className="photo-left-arrow" onClick={clieckLeft}/>
                <input placeholder={placeholder} ref={inputRef} type={type} className="id-input-inside" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} value={value} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => setVal(e.currentTarget.value)}/>
                :
                <input placeholder={placeholder} ref={inputRef} type={type} className="id-input-inside" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} value={value} onFocus={focusInput} onBlur={focusOutInput} onChange={(e) => setVal(e.currentTarget.value)}/>
                <FaChevronRight size={20} className="photo-left-arrow" onClick={clieckRight}/>
                <div className="id-clear" onClick={setFocus}>
                    {!!value && 
                    <IoClose className="id-clear-button"
                        onClick={(e) => {
                            clear(e);
                        }} 
                    />}
                </div>
            </div>
        </div>
    )
}

export default InputHourDirectiveComponent;