function DescriptionComponent({ items, content }) {
  return (
    <div className="description-style">
      <div className="padding-left-md padding-right-md">
        {content}
        {!!items && 
            items.map((item, idx) => (
                <div key={idx} className="description-paragraph">
                    <div className="descirption-title">
                        {item.title}
                    </div>
                    <div className="descirption-body">
                        {item.body}
                    </div>
                </div>
            ))
        }
      </div>
    </div>
  );
}

export default DescriptionComponent;
