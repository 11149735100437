import { AdminApiContext } from './context';
import { useAdminApiStore } from './store';

export const AdminApiContextProvider = ({children}) => {
  const store = useAdminApiStore();

  return (
    <AdminApiContext.Provider value={store}>
      {children}
    </AdminApiContext.Provider>
  );
}