import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { FaChevronLeft, FaChevronRight, FaPlus, FaAngleLeft, FaAngleRight  } from "react-icons/fa";
import { FaPencil, FaArrowsLeftRight } from "react-icons/fa6";
import useScreenDetector from "../../../hooks/useScreenDetector";
import ModalEditComponent from "../../modal/ModalEditComponent";
import MenuCardEditComponent from "./MenuCardEditComponent";
import NavBarGroupAdminComponent from "./NavBarGroupAdminComponent";
import MenuCardFiltersComponent from "./MenuCardFiltersComponent";
import { useI18nContext } from "../../../i18n/context/context";
import { useAdminApiContext } from '../../../hooks/admin/context';
import { useParams } from 'react-router-dom';
import { Sort } from '../../../helpers/arrays/sorting';
import debounce from 'lodash.debounce'
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";


function NavBarCardAdminComponent({ restaurantId, copy }) {
  const { translate, language } = useI18nContext();
  const [items, setItems] = useState([])
  const [count, setCount] = useState(1);
  const [isSaving, setIsSaving] = useState(false)
  const [selectedItem, setSelectItem] = useState(null);
  const {
    state: { cards = [], selectedCard } = {},
    setSelectedCard,
    updateCard,
    addCard,
    removeCard,
    changeCardsOrder
  } = useAdminApiContext();
  const [selectedObject, setSelectedObject] = useState(null)
  const [canSelectItem, setCanSelectItems] = useState(true)
  const [itemsRefs, setItemsRefs] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);


  const setItem = useCallback((item) => {
    setCanSelectItems(true);
    if(canSelectItem){
      setSelectItem(item);
    }
  }, [canSelectItem, selectedItem, selectedObject]);

  useEffect(() => {
    if(!!items && (!selectedItem || !items.includes(selectedItem))){
      setItem(items[0])
    }
    let refds = items.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setItemsRefs(refds);
  }, [items, canSelectItem, selectedItem])
   
  useEffect(() =>{
    let sliderProperties = ourRef?.current?.children[0]?.getBoundingClientRect();
    let itemProperties = itemsRefs[selectedItem]?.current?.getBoundingClientRect();
    if(!!sliderProperties && !!itemProperties){
      if(itemProperties.x + itemProperties.width > sliderProperties.x + sliderProperties.width){
        let destPosition = ((itemProperties.x + itemProperties.width) - (sliderProperties.x + sliderProperties.width)) + ourRef.current.children[0].scrollLeft;
        ourRef.current.children[0].scrollTo({left: destPosition+30});
      }
      if(itemProperties.x < sliderProperties.x){
        let destPosition = ourRef.current.children[0].scrollLeft + itemProperties.x - sliderProperties.x;
        ourRef.current.children[0].scrollTo({left: destPosition-30});
      }
    }
  }, [selectedItem])

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const setArrowsInfo = useCallback((sliderMenu) => {
    setShowLeftArrow(sliderMenu.scrollLeft > 1);
    setShowRightArrow(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1)
  }, [showLeftArrow, showRightArrow])

  const handleDragStart = useCallback((e) => {
    if (!ourRef.current) return;
    const sliderMenu = ourRef.current.children[0];
    const startX = e.pageX - sliderMenu.offsetLeft;
    const startY = e.pageY - sliderMenu.offsetTop;
    const scrollLeft = sliderMenu.scrollLeft;
    const scrollTop = sliderMenu.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.addEventListener("mouseup", mouseDownOutsideComponent);
  }, [ourRef, mouseCoords, isMouseDown]);

  const handleDragEnd = useCallback((e) => {
    setIsMouseDown(false);
    setCanSelectItems(true);
    document.removeEventListener("mouseup", mouseDownOutsideComponent, false);
    if (!ourRef.current) return;
  }, [ourRef, isMouseDown]);
  
  const handleDrag = useCallback((e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const sliderMenu = ourRef.current.children[0];
    const x = e.pageX - sliderMenu.offsetLeft;
    const y = e.pageY - sliderMenu.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    sliderMenu.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    sliderMenu.scrollTop = mouseCoords.current.scrollTop - walkY;
    if(Math.abs(mouseCoords.current.scrollLeft - sliderMenu.scrollLeft) > 5){
      setCanSelectItems(false);
    }
  }, [isMouseDown, ourRef]);

  const handleWheel = useCallback((e) => {
    if (!ourRef.current || (ourRef.current.children[0].clientWidth >= ourRef.current.children[0].scrollWidth)) return;
    e.preventDefault()
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + (e.deltaY/5);
  }, [ourRef, showLeftArrow, showRightArrow]);

  const mouseDownOutsideComponent = useCallback((event) => {
    const el = ourRef?.current
    if (!el || el.contains(event.target)) {
      return;
    }
    handleDragEnd();
  }, [ourRef])

  const clickLeft = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft - 30;
    setArrowsInfo(sliderMenu)
  }

  const clickRight = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + 30;
    setArrowsInfo(sliderMenu)
  }

  const handleScroll = (e) => {
    setArrowsInfo(ourRef.current.children[0])
  }

  const { sizeScreen } = useScreenDetector();

  useEffect(() => {
    if(!ourRef)
      return;

    const sliderMenu = ourRef?.current?.children[0];
    if(!sliderMenu){
      return
    }
    if(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1){
      sliderMenu.addEventListener("wheel", handleWheel)
      sliderMenu.addEventListener("scroll", handleScroll);
      setArrowsInfo(sliderMenu);
    }
    else{
      sliderMenu.removeEventListener("wheel", handleWheel, false)
      sliderMenu.removeEventListener("scroll", handleScroll, false);
      setArrowsInfo(sliderMenu);
    }
  }, [ourRef, sizeScreen])

  const [newItem, setNewItem] = useState(false)

  const [isEditItem, setIsEditItem] = useState(false)

  const [editItemOldValue, setEditItemOldValue] = useState({})

  const addNewItem = (val) => {
    if(val){
      addCard(restaurantId, {name: val})
    }
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue({})
  }

  const addNewCard = (val) => {
    let mCards = sortedCards;
    if(mCards.length == 1 && mCards[0].code=="specialEmptyCard"){
      mCards[0].id = 0;
      mCards[0].name = val;
      mCards[0].code = generateNewCardCode(0);
    }
    else{
      let newId = Math.max(...mCards.map(x => x.id)) + 1;
      let newItem = {
        id: newId,
        code: generateNewCardCode(newId),
        language: language,
        filters: {
          dishType: false,
          size: false,
          price: false,
          includedItems: false,
          excludedItems: false,
          allergens: false,
          isLactoseFree: false,
          isGlutenFree: false,
          isVegan: false,
          isVegetarian: false
        },
        name: val,
        groups: [{
          id: null,
          code: "specialEmptyGroup",
          language: language,
          name: translate("specialEmptyGroup"),
          items: []
        }]
      }
      mCards.push(newItem);
    }
    
    setItems(mCards.map(x => x.name))
    setItem(val)
  }

  const edit = (newValName) => {
    editCard(editItemOldValue, {...editItemOldValue, name: newValName})
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue({})
  }

  const openAddCard = () => {
    setNewItem(true);
  }

  const editItem = (item) => {
    setEditItemOldValue(item)
    setIsEditItem(true)
  }

  const removeItem = () => {
    removeCard(editItemOldValue.id)
  }

  const generateNewCardCode = (id) => {
    return restaurantCode.concat(restaurantCode).concat("Card").concat(id);
  }

  const editCard = (oldValue, newValue) => {
    if(oldValue == newValue){
      return;
    }
    updateCard(oldValue.id, newValue);
  }

  const changeFilterValue = (filter, val) => {


    selectedCard.filtersConfig[filter] = val;

    setIsSaving(true)
    updateCard(selectedCard.id, selectedCard)
    setIsSaving(false)
  }

  const changeFilterVal = () => {
    setIsSaving(true)
    updateCard(selectedCard.id, selectedCard)
    setIsSaving(false)
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const myItems = Array.from(cards);
    const [reorderedItem] = myItems.splice(result.source.index, 1);
    myItems.splice(result.destination.index, 0, reorderedItem);
    // setOrders(myItems)
    await changeCardsOrder(restaurantId, myItems.map(({id}) => id))
  }

  const sortedCards = useMemo(() => cards.sort((a, b) => Sort.numericAsc(a.order, b.order)), [cards]);
  
  const debouncedOnChange = useCallback(debounce(changeFilterVal, 500), []);

  return (
    <>
      {isSaving && 
        <LoaderDirectiveComponent />
      }
      <div className="header-menu-scroll-admin">
        {showLeftArrow &&
        (<div className="card-left-arrow" onClick={clickLeft}>
          <FaChevronLeft size={20} className="menu-left-arrow-nav-bar-scroll"/>
        </div>)
        }
      
        <div
          ref={ourRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
          className="horizontal-menu-container scroll-horizontal"
        >
          <div id="sliderMenu" className="container-menu scroll-horizontal padding-left-mx">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable direction='horizontal' droppableId="items">
                {(provided) => (
                  <div style={{display: 'flex'}} {...provided.droppableProps} ref={provided.innerRef}>
                    {!!sortedCards && (
                      sortedCards.map((card, idx) => (
                        <Draggable key={card.id} draggableId={card.id} index={idx}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div key={idx} className="cursor-pointer" onClick={() => setSelectedCard(card.id)}>
                                <div className={`card-menu-item display-flex ${card.id == selectedCard.id ? "card-menu-item-selected" : ""} ${sortedCards.length == 1 && sortedCards[0].code=="specialEmptyCard" ? "special-empty-element" : ""}`}>
                                  <div ref={itemsRefs[card]}>
                                    {card.name}
                                  </div>
                                  {sortedCards[0].code != "specialEmptyCard" &&
                        <div onClick={(e) => { e.stopPropagation(); editItem(card)}} >
                          <FaPencil className="margin-left-md" size={15} />
                        </div>
                                  }
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {newItem && 
                <>
                  <ModalEditComponent setOpen={setNewItem} model={""} validModel={items} setModelChanges={(val) => addNewItem(val)} hideLanguages >
                    <MenuCardEditComponent />
                  </ModalEditComponent>
                </>
            }
              
            {isEditItem && 
                <>
                  <ModalEditComponent
                    setOpen={setIsEditItem}
                    model={editItemOldValue.name}
                    validModel={sortedCards.filter(({ id }) => id != editItemOldValue.id)}
                    setModelChanges={edit}
                    deleteItem={removeItem}
                    hideLanguages
                  >
                    <MenuCardEditComponent />
                  </ModalEditComponent>
                </>
            }

            <div className="card-menu-item" onClick={() => openAddCard()}>
              <FaPlus size={15} className=""/>
            </div>
            
            <div className="fake-card-menu-item"></div>
            <div className="fake-right-card-menu-item"></div>
          </div>
          {showRightArrow && 
            <div className="card-right-arrow" onClick={clickRight}>
              <FaChevronRight size={20} className="menu-right-arrow-nav-bar-scroll"/>
            </div>
          }
        </div>
      </div>
      <div className="edit-card-inside-container">
        {!!selectedCard?.filtersConfig && <MenuCardFiltersComponent filtersConfig={selectedCard?.filtersConfig} changeFilterValue={changeFilterValue} />}
        <div className="edit-body-container">
          {!!selectedCard && <NavBarGroupAdminComponent card={selectedCard} copy={copy}/>}
        </div>
      </div>
    </>
    
  );
}

export default NavBarCardAdminComponent;
