import "../../css/restaurant.css";
import { GoDotFill } from "react-icons/go";
import { useOpeningHours } from '../../hooks/useOpeningHours';
import { getFormattedAddress } from '../../helpers/formatting';
import { useI18nContext } from '../../i18n/context/context';

function RestaurantResultItemComponent({ restaurant }) {
  const { translate } = useI18nContext();
  const openingHours = useOpeningHours(restaurant);

  return (
    <div>
        <div className="">
          <img
            src={restaurant.mainPhotoUrl}
            className="home-restaurant-backgruond-picture"
          />
        </div>
        <div className="display-flex">
          <div className="home-restaurant-picture-container">
            <img src={restaurant.logoPhotoUrl} className="home-restaurant-picture" />
          </div>
          <div className="hm-restaurant-name-cointener">
            <div className="hm-restaurant-name-style">
              {restaurant.restaurantName}
            </div>
          </div>
        </div>
        <div className="display-flex">
          <div className="hm-restaurant-details-cointener padding-bottom-sm">
            <div className="hm-restaurant-description-style">
              {!!restaurant.restaurantType && (
                <span className="hm-restaurant-label">
                  {translate("restaurantType")}
                </span>
              )}
              {!!restaurant.restaurantType && (
                <span>{restaurant.restaurantType}</span>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!restaurant.address && (
                <span className="hm-restaurant-label">{translate("address")}</span>
              )}
              {!!restaurant.address && (
                <span>
                  {getFormattedAddress(restaurant.address)}
                  , {restaurant.address.city}
                </span>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!openingHours.hours && (
                <span className="hm-restaurant-label">
                  {translate("openingHours")}
                </span>
              )}
              {!!openingHours.hours && (
                <div className="hm-opening-days"> 
                {  
                  openingHours.hours.map((item, index) => 
                  (
                    <div key={index}> {translate(item.days[0])}{item.days.length == 2 && ", "} {item.days.length > 2 && " - "} {item.days.length > 1 && translate(item.days[item.days.length-1])}</div>
                  ))
                }
                </div>
              )}
              {!!openingHours.hours && (
                <div> 
                {  
                  openingHours.hours.map((item, index) => 
                  (
                    <div key={index}>{item.time}</div>
                  ))
                }
                </div>
              )}
            </div>
            <div className="hm-restaurant-description-style">
              {!!openingHours.openNow && (
                <span><GoDotFill className="hm-restaurant-open-now" />{translate("openNow")}</span>
              )}
              {!openingHours.openNow && (
                <span><GoDotFill className="hm-restaurant-close-now" />{translate("closeNow")}</span>
              )}
            </div>
          </div>
        </div>
    </div>
  );
}

export default RestaurantResultItemComponent;
