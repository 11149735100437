/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import useScreenDetector from "../../hooks/useScreenDetector";
import { useI18nContext } from "../../i18n/context/context";
import PosterEditorComponent from '../../components/admin/poster/PosterEditorComponent';
import AnnouncementEditorComponent from '../../components/admin/announcement/AnnouncementEditorComponent';


function AdditionalWindows({ Restaurant }) {
  const { translate, language } = useI18nContext();
  
  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);

  useEffect(() => {
    const mTabs = ['starter', 'announcement'];
    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      SetActiveTab(mTabs[0]);
    }
  }, [sizeScreen])

  return (
    <>
      <div className="admin-header-underline" />
      <div className="admin-body">
      <div>
        { !!tabs &&
          <div className="admin-tabs-container">
            {tabs.map((tab, idx) => (
              <div key={idx} className={`admin-tab ${tab == activeTab ? "selected-admin-tab" : ""}`} onClick={() => SetActiveTab(tab)}>
                {translate(tab)}
              </div>
            ))}
        </div>
        }
      </div>
          

          {activeTab === 'starter' && <PosterEditorComponent restaurantId={Restaurant?.id} />}
          {activeTab === 'announcement' && <AnnouncementEditorComponent restaurantId={Restaurant?.id} />}
        </div>
    </>);
}

export default AdditionalWindows;
