import { BaseApi } from '../shared/baseApi';
import { createDeleteRequest, createGetRequest, createPostRequest } from '../shared/fetch';

export class RestaurantMediaApi extends BaseApi {
  async add(restaurantCode, file) {
    const data = new FormData();
    data.append('file', file);

    return await createPostRequest(
      `restaurants/${restaurantCode}/media`,
      data,
      this._language,
      null
    );
  }

  getList(restaurantCode) {
    return createGetRequest(`restaurants/${restaurantCode}/media`);
  }

  remove(restaurantCode, mediaId) {
    return createDeleteRequest(`restaurants/${restaurantCode}/media/${mediaId}`);
  }
}