import { useState, useEffect, useRef } from "react";
import { Tooltip, Whisper } from 'rsuite';

function IconWithTooltipComponent({icon, text, parentRef}){
    const [generatedId, SetGeneratedId] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const myRef = useRef(null);
    const whisperTooltipRef = useRef(null);


    const tooltip = (
        <Tooltip className="tooltip-style">
          {text}
        </Tooltip>
      );

    useEffect(() => {
        if(!!myRef && !!myRef.current){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("iconWithTooltip".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    useEffect(() => {
        if(!!whisperTooltipRef && !!whisperTooltipRef.current){
            // let element = myRef.current.getBoundingClientRect();
            // SetGeneratedId("tooltip".concat(element.x).concat(element.y)); 
        }
    }, [whisperTooltipRef])


    const handleTooltipClick = (e) => {
        if(!!parentRef && !!parentRef.current){
            setTooltipOpen(true);
        }
    }

    const tooltipCloseEvent = (e) => {
        setTooltipOpen(false);
    }

    useEffect(() => {
        if(!!parentRef && !!parentRef.current){
            parentRef.current.addEventListener("scroll", tooltipCloseEvent, false);
            parentRef.current.addEventListener("click", tooltipCloseEvent, false);
            return () => {
                parentRef.current?.removeEventListener("scroll", tooltipCloseEvent, false);
                parentRef.current?.removeEventListener("click", tooltipCloseEvent, false);
            }
        }
    }, [parentRef])

    return (
        <span id={generatedId} ref={myRef}>
            <Whisper placement="topStart" ref={whisperTooltipRef} controlId="control-id-click" onClick={handleTooltipClick} open={tooltipOpen} speaker={tooltip}>
                <img
                    className="icon-style cursor-pointer"
                    src={icon}
                />
            </Whisper>
        </span>
    )
}

export default IconWithTooltipComponent;