import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import "../../../css/global.css";
import { useI18nContext } from '../../../i18n/context/context';

function NavBarScrollFroMapItemComponent({ items, setSelectedMenuItem, DefActiveTab }) {
  const { translate } = useI18nContext();
  const [selectedItem, setSelectItem] = useState(items[0]);
  const [itemsRefs, setItemsRefs] = useState([]);
  const setItem = useCallback((item) => {
    setSelectItem(item);
    setSelectedMenuItem(item);
  }, [selectedItem]);


  useEffect(() => {
    if(!!DefActiveTab){
      setSelectItem(DefActiveTab);
    }
  }, [DefActiveTab])

  useEffect(() => {
    let refds = items.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setItemsRefs(refds);
  }, [items])
  
  useEffect(() =>{
    let sliderProperties = ourRef?.current?.children[0]?.getBoundingClientRect();
    let itemProperties = itemsRefs[selectedItem]?.current?.getBoundingClientRect();
    if(!!sliderProperties && !!itemProperties){
      if(itemProperties.x + itemProperties.width > sliderProperties.x + sliderProperties.width){
        let destPosition = ((itemProperties.x + itemProperties.width) - (sliderProperties.x + sliderProperties.width)) + ourRef.current.children[0].scrollLeft;
        ourRef.current.children[0].scrollTo({left: destPosition});
      }
      if(itemProperties.x < sliderProperties.x){
        let destPosition = ourRef.current.children[0].scrollLeft + itemProperties.x - sliderProperties.x;
        ourRef.current.children[0].scrollTo({left: destPosition});
      }
    }
  }, [selectedItem])

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const handleDragStart = useCallback((e) => {
    if (!ourRef.current) return;
    const sliderMenu = ourRef.current.children[0];
    const startX = e.pageX - sliderMenu.offsetLeft;
    const startY = e.pageY - sliderMenu.offsetTop;
    const scrollLeft = sliderMenu.scrollLeft;
    const scrollTop = sliderMenu.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
  }, [ourRef, mouseCoords, isMouseDown]);

  const handleDragEnd = useCallback(() => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
  }, [ourRef, isMouseDown]);
  
  const handleDrag = useCallback((e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const sliderMenu = ourRef.current.children[0];
    const x = e.pageX - sliderMenu.offsetLeft;
    const y = e.pageY - sliderMenu.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    sliderMenu.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    sliderMenu.scrollTop = mouseCoords.current.scrollTop - walkY;
  }, [isMouseDown, ourRef]);

  return (
    <div className="">
      <div
          ref={ourRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
          className="padding-left-sx padding-right-sx horizontal-menu-container scroll-horizontal"
          >
          <div id="sliderMenu" className="container-menu scroll-horizontal">
              {!!items &&
              items.map((item, idx) => (
                <div key={idx}>
                  <div onClick={() => setItem(item)} ref={itemsRefs[item]} className={`hm-manu-item ${item == selectedItem ? "hm-menu-item-selected" : ""}`}>
                    {translate(item)}
                  </div>
                </div>
              ))}
          </div>
      </div>
  </div>
    
  );
}

export default NavBarScrollFroMapItemComponent;
