import "./ModalEdit.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from '@mui/material/Modal';
import { Button } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import { FaXmark, FaCheck } from "react-icons/fa6";
import useScreenDetector from "../../hooks/useScreenDetector";
import { FaTrashAlt  } from "react-icons/fa";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";

function ModalEditComponent({children, model, setOpen, setModelChanges, validModel, hideFooter, deleteItem, hideSaveButton, hideCancelButton, hideDeleteButton, hideLanguages}) {
    const { translate } = useI18nContext();
    const { sizeScreen } = useScreenDetector();

    const [isSaving, setIsSaving] = useState(false)
    const [isOpen, setIsOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {}

    const [myStyle, setMyStyle] = useState({})
    const myRef = useRef(null);

    useEffect(() => {
      if(typeof model == "object" || typeof model == "function"){
        if(!!myRef?.current){
          let elementInfo = myRef?.current.getBoundingClientRect();
          let footerInfo = myRef?.current.children[1].getBoundingClientRect();
          setMyStyle({
            height: elementInfo.height - footerInfo.height
          })
        }
      }else{
        let myVal = model
        setBackupModel(myVal)
      }
  }, [myRef])

  useEffect(() => {
    if(typeof model == "object" || typeof model == "function"){
      if(!!myRef?.current){
        let elementInfo = myRef?.current.getBoundingClientRect();
        let footerInfo = myRef?.current.children[1].getBoundingClientRect();
        setMyStyle({
          height: elementInfo.height - footerInfo.height
        })
      }
    }else{
      let myVal = model
      setBackupModel(myVal)
    }

    
  }, [sizeScreen, myRef])


  const [isDeletedQuestion, setIsDeletedQuestion] = useState(false)
  const [isSomethingChanges, setIsSomethingChanges] = useState(false)
  const [canSave, setCanSave] = useState(false)

  const [myModel, setMyModel] = useState(null)
  const [backupModel, setBackupModel] = useState(null)

  useEffect(() => {
    if(!!model){
      setMyModel(model)
      if(typeof model == "object" || typeof model == "function"){
        setBackupModel(Object.assign({}, model))
      }else{
        let myVal = model
        setBackupModel(myVal)
      }
    }
  }, [model])

  const setChanges = (changedModel) => {
    setMyModel(changedModel)
    setIsSomethingChanges(true)
  }

  const save = () => {
    setModelChanges(myModel)
    setIsOpen(false)
    // setIsSaving(true)
    setOpen(false)
  }

  const close = useCallback(() => {
    // if(typeof model == "object" || typeof model == "function"){
    //   model = Object.assign({}, backupModel)
    //   // setModelChanges(backupModel)
    // }else{
    //   model = backupModel
    // } 
    // setMyModel(backupModel)
    setOpen(false)
  }, [backupModel, myModel, model])

  const deleteObject = () => {
    deleteItem()
    setOpen(false)
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { model, validModel, setChanges, setCanSave, canSave });
    }
    return child;
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}>
          <div>
            {isSaving &&
              <LoaderDirectiveComponent />
            }
            <div ref={myRef} className="modal-edit-container">
              <div className={`modal-edit-body ${isDeletedQuestion ? "modal-edit-body-deleted-question" : ""}`}>
                {!!hideLanguages &&
                  <div className="modal-edit-body-main">
                    {childrenWithProps}
                  </div>
                }
                {!hideLanguages &&
                  <>
                    <div className="modal-edit-body-main-with-language">
                      {childrenWithProps}
                    </div>
                    <div className="modal-edit-body-languages">
                      
                    </div>
                  </>
                }
              </div>
              {!hideFooter && 
                <div className="modal-edit-footer">
                  <div className="modal-edit-footer-left">
                      <Button color="green" onClick={save} disabled={isDeletedQuestion || !isSomethingChanges || !canSave} appearance="primary" className="modal-edit-footer-button">
                        {translate("save")}
                      </Button>
                      <Button color="red" onClick={close} disabled={isDeletedQuestion} appearance="primary" className="modal-edit-footer-button">
                        {translate("cancel")}
                      </Button>
                  </div>
                  <div className="modal-edit-footer-right">
                    {isDeletedQuestion && 
                      <>
                        <FaCheck size={30} onClick={deleteObject} className="modal-edit-footer-button" />
                        <FaXmark size={30} onClick={() => setIsDeletedQuestion(false)} className="modal-edit-footer-button" />
                      </>
                    }
                    {!isDeletedQuestion && !hideDeleteButton &&
                      <FaTrashAlt size={30} onClick={() => setIsDeletedQuestion(true)} className="modal-edit-footer-button"/>
                    }
                  </div>
                </div>
              }
            </div>
          </div>             
      </Modal>
    </>
    
  );
}

export default ModalEditComponent;
