import "../../css/filters.css";
import React from "react";
import FiltersHomeBodyComponent from "./FiltersHomeBodyComponent";

function FiltersHomeComponent({ configuration, setFilterData, filters }) {

  return (
    <div className="filter-container-style sticky-zero-position-style">
      <div className="filter-overflow-auto">
        <div className="fake-field-mx"></div>
        <FiltersHomeBodyComponent
          setFilterData={setFilterData}
          configuration={configuration}
          filters={filters}
        />
      </div>
    </div>
  );
}

export default FiltersHomeComponent;
