import { useState, useEffect } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_APIURL;

const useGet = ({ method, params, isParamsNecessary }) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    if (!isParamsNecessary || !!params) {
      let url = API_URL.concat(method);
      axios.get(url, params).then((res) => {
        setData(res.data);
        setIsPending(false);
      });
    } else {
      setData(null);
      setIsPending(false);
    }
  }, [method, params]);

  return { data, isPending };
};

export default useGet;
