// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-clear-text{
    margin: auto;
    width: 100%;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    font-weight: bold;
    -webkit-user-select: none;
    user-select: none;
    padding-left: 10px;
}

.filters-clear-container{
    /* opacity: 0.5; */
    cursor: pointer;
}

.filters-clear-container:hover{
    color: var(--sefooColor);
}

.selected-filter-border-color{
    border-color: var(--sefooColor);
}`, "",{"version":3,"sources":["webpack://./src/css/filters.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW,WACqB,4CAA4C;IAC5E,6BAA6B,GAAG,6CAA6C;IAC7E,qBAAqB;IACrB,iBAAiB;IACjB,yBAAyB;IAEzB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".filters-clear-text{\n    margin: auto;\n    width: 100%;\n    width: -moz-available;          /* WebKit-based browsers will ignore this. */\n    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */\n    width: fill-available;\n    font-weight: bold;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    padding-left: 10px;\n}\n\n.filters-clear-container{\n    /* opacity: 0.5; */\n    cursor: pointer;\n}\n\n.filters-clear-container:hover{\n    color: var(--sefooColor);\n}\n\n.selected-filter-border-color{\n    border-color: var(--sefooColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
