import "../../../css/filters.css";
import React from "react";
import FiltersComponent from "../left/FiltersComponent";

function FilterRestaurantHidingComponent({ SetFilterData, config, SetFilterClose, filters }) {
  
  return (
    <div id="filtersAutoContainer" className="filter-hidding-container-style sticky-zero-position-style">
      <div className="filter-overflow-auto">
        <FiltersComponent 
          SetFilterData={SetFilterData}
          config={config}
          filters={filters}
          SetFilterClose={SetFilterClose}
        />
        <div className="fake-field-mx"></div>
      </div>
    </div>
  );
}

export default FilterRestaurantHidingComponent;
