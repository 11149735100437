import Toggle from 'rsuite/Toggle';

import './ToggleWithLabel.css';

const ToggleWithLabel = ({label, checked, onChange}) => {
  return (
    <span className="ToggleWithLabel">
      <span className={`padding-right-xl label cursor-pointer ${checked ? "selected-label" : ""}`} onClick={(e) => onChange(!checked)}>{label}</span>
      <Toggle
        onChange={onChange}
        checked={checked}
        size="md"
      />
    </span>
  );
}

export default ToggleWithLabel;
