import React, { useState, useEffect, useRef }  from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, {Icon} from 'leaflet'
import { useI18nContext } from "../../i18n/context/context";
import sefooMarker from '../../assets/images/sefooMarker.png'
import greenMarker from '../../assets/images/greenMarker.png'
import MarkerClusterGroup from "react-leaflet-cluster";
import logoPictureSefoo from "../../assets/images/logo/sefoo.svg"
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";


function MapDefComponent({ restaurants, selectedItem, setSelectedItem, defPosition }) {
  const [position, setPosition] = useState([50.07405928030033, 19.97536984701493]);
  const [zoom, setZoom] = useState(9);
  const { translate, language } = useI18nContext();
  const markersRef = useRef([]);

  useEffect(() => {
    if(defPosition){
      setPosition(defPosition);
      setZoom(10);
    }
  }, [defPosition])

  useEffect(() => {
    if(markersRef && restaurants){
      if(!markersRef.current?.[restaurants.findIndex(x => x.restaurantCode == selectedItem?.restaurantCode)]?.isPopupOpen()){
        markersRef.current?.[restaurants.findIndex(x => x.restaurantCode == selectedItem?.restaurantCode)]?.openPopup();
      }
      restaurants.filter(x => x.restaurantCode != selectedItem?.restaurantCode)?.forEach(x => {
        markersRef.current?.[restaurants.findIndex(y => y.restaurantCode == x?.restaurantCode)]?.closePopup();
      })
    }
  }, [restaurants, selectedItem, markersRef])

  useEffect(() => { 
      const observer = new MutationObserver((mutationsList, observer) => { 
        const elements = document.getElementsByClassName('leaflet-attribution-flag'); 
        if (elements.length > 0) { 
          Array.from(elements).forEach(element => element.remove());
          observer.disconnect(); 
        } 
      }); 
      observer.observe(document.body, { childList: true, subtree: true }); 
      return () => { observer.disconnect(); }; 
    }, [language]);

  const defaultIcon = new Icon ({
    iconUrl: sefooMarker,
    iconSize: [30, 30],
    popupAnchor: [-3, -20]
  })

  const greenIcon = new Icon ({
    iconUrl: greenMarker,
    iconSize: [35, 35],
    popupAnchor: [-3, -20]
  })

  const getIcon = (item) => {
    if(item.restaurantCode == selectedItem?.restaurantCode){
      return greenIcon;
    }
    return defaultIcon;
  }

  return (
    <div className="home-map-container" >
      <MapContainer center={position} zoom={zoom}  scrollWheelZoom={true} className="admin-basic-map">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> authors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          className= 'map-tiles'
        />
        <MarkerClusterGroup maxClusterRadius={30}>
          {!!restaurants && restaurants.map((item, idx) => (
            <Marker
              ref={(el) => (markersRef.current[idx] = el)}
              key={idx}
              position={{lat: item.location.x, lng: item.location.y}}
              icon={getIcon(item)}
              eventHandlers={{
                // popupopen: () => setSelectedItem(item),
                popupclose: () => setSelectedItem(null),
                click: () => {selectedItem?.restaurantCode != item.restaurantCode ? setSelectedItem(item) : setSelectedItem(null)}

              }}>
                <Popup className="home-marker-popup">
                  <div className='home-restaurants-item-popup' onClick={(e) => {window.location.href = window.location.origin.concat('/').concat(item?.restaurantCode)}}>
                    <div className="display-flex">
                      <div className="home-restaurants-list-item-header">
                          <div className="">
                            {selectedItem?.mainPhotoUrl &&
                              <img src={selectedItem.mainPhotoUrl} className="popup-borders restaurant-selected-backgruond-picture" />
                            }
                          </div>
                          <div className="display-flex">
                          {selectedItem?.logoPhotoUrl &&
                              <div className={`restaurant-picture-container ${!selectedItem.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
                                <img src={selectedItem.logoPhotoUrl} className="restaurant-picture" />
                              </div>
                            }
                            {!selectedItem?.logoPhotoUrl &&
                                                    <div className={`restaurant-picture-container ${!selectedItem?.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
                                                      <img src={logoPictureSefoo} className="restaurant-picture" />
                                                    </div>
                                                  }
                              <div className="restaurant-name-cointener">
                                  <div className="restaurant-name-style">
                                  {selectedItem?.restaurantName}
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default MapDefComponent;
