import PropTypes from 'prop-types';
import { useState, useEffect, useCallback} from "react";
import cx from 'classnames';

const SelectOptionsObjectComponent = ({
  options,
  onChange,
  listRef,
  value
}) => {
  const [hoverIdx, setHoverIdx] = useState(-1)

  const keyDownFunction = useCallback((e) => {
    if(e.code == "ArrowDown"){
      e.preventDefault();
      if(hoverIdx >= options.length - 1){
        setHoverIdx(0)
      }
      else{
        setHoverIdx(hoverIdx+1)
      }
    }
    if(e.code == "ArrowUp"){
      e.preventDefault();
      if(hoverIdx == 0){
        setHoverIdx(options.length - 1)
      }
      else{
        setHoverIdx(hoverIdx-1)
      }
    }
    if(e.code == "Enter" && hoverIdx >= 0){
      onChange(options[hoverIdx].value)
    }
  }, [options, hoverIdx])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [hoverIdx, setHoverIdx])

  return (
    <div ref={listRef} className="sd-list-items" onKeyDown={(e) => keyDownFunction(e)}>
      {!!options && options.map((option, idx) => (
        <div
          key={idx}
          className={cx('sd-item', {
            "sd-item-selected": option.value == value,
            "sd-item-hover": hoverIdx == idx
          })}
          onClick={() => onChange(option.value)}
          onMouseOver={(e) => setHoverIdx(idx)}
        >
          {option.text}
        </div>
      ))}
    </div>
  )
}

SelectOptionsObjectComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SelectOptionsObjectComponent;