import React, { useMemo } from "react";
import MultiSelectDirectiveNewComponent from "../../../../directives/MultiSelectDirectiveNewComponent";
import { useI18nContext } from '../../../../i18n/context/context';

function IncludedExcludedItemsFilterComponent({
  SetFilterValue,
  data,
  value
}) {
  const { translate } = useI18nContext();
  const excludedData = useMemo(() => (
    data.filter((x) => !value.includedItems.includes(x))
  ), [data, value]);

  const includedData = useMemo(() => (
    data.filter((x) => !value.excludedItems.includes(x))
  ), [data, value]);

  return (
    <span>
      <div className="margin-bottom-md">
        <div className={`label padding-bottom-sx ${value.includedItems.length > 0 ? "selected-label" : ""}`}>
          {translate("includedItems")}
        </div>
        <div className="col-11 filter-min-width">
          <MultiSelectDirectiveNewComponent
            placeholder={translate("select")}
            options={includedData}
            SetResult={(includedItems) => SetFilterValue({ ...value, includedItems })}
            defSelectedItems={value.includedItems ?? []}
          />
        </div>
      </div>
      <div>
        <div className={`label padding-bottom-sx ${value.excludedItems.length > 0 ? "selected-label" : ""}`}>
          {translate("excludedItems")}
        </div>
        <div className="col-11 filter-min-width">
          <MultiSelectDirectiveNewComponent
          placeholder={translate("select")}
          options={excludedData}
          SetResult={(excludedItems) => SetFilterValue({ ...value, excludedItems })}
          defSelectedItems={value.excludedItems ?? []}
          />
        </div>
      </div>
    </span>
  );
}

export default IncludedExcludedItemsFilterComponent;
