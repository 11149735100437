import { useState } from 'react';
import { useI18nContext } from '../../i18n/context/context';
import { ApiLibrary } from '../../helpers/api/ApiLibrary';
import { useLogger } from '../logger/useLogger';

export const useAdminApiStore = () => {
  const [state, setState] = useState();
  const logger = useLogger();
  const { language } = useI18nContext();

  const fetchCards = async (restaurantCode) => {
    try {
      const response = await new ApiLibrary(language).restaurants.general.getByCode(restaurantCode);
      if (!response.data) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        cards: response.data.menus,
        selectedCard: response.data.menus?.sort((x, y) => x.order - y.order)[0]
      }));
    } catch(error) {
      logger.error(error);
    }
  }

  const setSelectedCard = (cardId) => {
    setState((prevState) => ({
      ...prevState,
      selectedCard: prevState.cards?.find(({ id }) => id === cardId)
    }));
  }

  const updateCard = async (cardId, cardUpdatePayload) => {
    try {
      const response = await new ApiLibrary(language).menu.cards.update(cardId, {
        name: {
          languageCode: language,
          text: cardUpdatePayload.name
        },
        filtersConfig: cardUpdatePayload.filtersConfig
        // order: cardUpdatePayload.order,
      })

      if (!response?.data) {
        logger.error(response.error);
        return;
      }

      setState((prevState) => ({
        ...prevState,
        cards: prevState.cards.map((card) => cardId === card.id ? response.data : card),
        selectedCard: response.data,
      }));
    } catch(error) {
      logger.error(error);
    }

  }

  const removeCard = async (cardId) => {
    try {
      const response = await new ApiLibrary(language).menu.cards.remove(cardId);

      if (!response?.data) {
        logger.error(response.error)
        return;
      }

      setState((prevState) => {
        const cards = prevState.cards.filter((card) => cardId !== card.id);
        return {
          ...prevState,
          cards,
          selectedCard: prevState.selectedCard.id === cardId ? cards?.[cards.length - 1] : prevState.selectedCard,
        }
      });
    } catch(error) {
      logger.error(error)
    }
  }

  const addCard = async (restaurantId, cardCreatePayload) => {
    const response = await new ApiLibrary(language).menu.cards.add(restaurantId, {
      name: {
        languageCode: language,
        text: cardCreatePayload.name
      },
      // order: Math.max(state.cards.map(({ order }) => order), state.cards.length),
    })

    if (!response?.data) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      cards: [...prevState.cards, response.data],
      selectedCard: response.data,
    }));
  }

  const changeCardsOrder = async (restaurantId, cardIds) => {
    const cards = state.cards;

    setState((prevState) => {
      const cards = prevState.cards.map((card) => ({...card, order: cardIds.indexOf(card.id)}));
      return {
        ...prevState,
        cards,
      }
    });

    const response = await new ApiLibrary(language).menu.cards.changeOrder(restaurantId, cardIds);

    if (!response?.data) {
      setState((prevState) => ({
        ...prevState,
        cards,
      }));
      return;
    }
  }

  const fetchCategories = async (cardId) => {
    const response = await new ApiLibrary(language).menu.cards.get(cardId);

    if (!response?.data) {
      return;
    }

    setState((prevState) => {
      const categories = response.data.categories;
      return {
        ...prevState,
        categories,
        selectedCategory: categories?.sort((x, y) => x.order - y.order)[0]
      }
    });
  }

  const setSelectedCategory = (categoryId) => {
    setState((prevState) => ({
      ...prevState,
      selectedCategory: prevState.categories?.find(({ id }) => id === categoryId)
    }));
  }

  const updateCategory = async (categoryId, categoryUpdatePayload) => {
    const response = await new ApiLibrary(language).menu.categories.update(categoryId, {
      name: {
        languageCode: language,
        text: categoryUpdatePayload.name
      },
      // order: categoryUpdatePayload.order,
    })

    if (!response?.data) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      categories: prevState.categories.map((category) => categoryId === category.id ? response.data : category),
      selectedCategory: response.data,
    }));
  }

  const removeCategory = async (categoryId) => {
    const response = await new ApiLibrary(language).menu.categories.remove(categoryId);

    if (!response?.data) {
      return;
    }

    setState((prevState) => {
      const categories = prevState.categories.filter((category) => categoryId !== category.id);
      return {
        ...prevState,
        categories,
        selectedCategory: categories?.[categories.length - 1],
      }
    });
  }

  const addCategory = async (cardId, categoryCreatePayload) => {
    const response = await new ApiLibrary(language).menu.categories.add(cardId, {
      name: {
        languageCode: language,
        text: categoryCreatePayload.name
      },
      // order: Math.max(state.categories.map(({ order }) => order), state.categories.length),
    })

    if (!response?.data) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      categories: [...prevState.categories, response.data],
      selectedCategory: response.data,
    }));
  }

  const changeCategoriesOrder = async (catalogId, categoriesIds) => {
    const categories = state.categories;

    setState((prevState) => {
      const categories = prevState.categories.map((category) => ({...category, order: categoriesIds.indexOf(category.id)}));
      return {
        ...prevState,
        categories,
      }
    });

    const response = await new ApiLibrary(language).menu.categories.changeOrder(catalogId, categoriesIds);

    if (!response?.data) {
      setState((prevState) => ({
        ...prevState,
        categories,
      }));
      return;
    }
  }

  const fetchDishes = async (categoryId) => {
    setState((prevState) => {
      return {
        ...prevState,
        dishes: [],
        isLoading: true
      }
    });

    const response = await new ApiLibrary(language).menu.categories.getDishes(categoryId);

    if (!response?.data) {
      return;
    }

    setState((prevState) => {
      const dishes = response.data;
      return {
        ...prevState,
        dishes,
        selectedDish: dishes?.[dishes.length - 1],
        isLoading: false
      }
    });
  }

  const setSelectedDish = (dishId) => {
    setState((prevState) => ({
      ...prevState,
      selectedDish: prevState.dishes?.find(({ id }) => id === dishId)
    }));
  }

  const addDish = async (cardId, categoryId, dishCreatePayload) => {
    const response = await new ApiLibrary(language).menu.dishes.add(cardId, categoryId, {
      ...dishCreatePayload,
      // order: Math.max(state.dishes.map(({ order }) => order), state.dishes.length),
    })

    if (!response?.data) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      dishes: [...prevState.dishes, response.data],
      selectedDish: response.data,
    }));
  }

  const updateDish = async (dishId, dishUpdatePayload) => {
    const response = await new ApiLibrary(language).menu.dishes.update(dishId, {
      ...dishUpdatePayload,
    })

    if (!response?.data) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      dishes: prevState.dishes.map((dish) => dishId === dish.id ? response.data : dish),
      selectedDish: response.data,
    }));
  };

  const removeDish = async (dishId) => {
    const response = await new ApiLibrary(language).menu.dishes.remove(dishId);

    if (!response?.data) {
      return;
    }

    setState((prevState) => {
      const dishes = prevState.dishes.filter((dish) => dishId !== dish.id);
      return {
        ...prevState,
        dishes,
        selectedDish: dishes?.[dishes.length - 1],
      }
    });
  }

  const changeDishesOrder = async (categoryId, dishIds) => {
    const dishes = state.dishes;

    setState((prevState) => {
      const dishes = prevState.dishes.map((dish) => ({...dish, order: dishIds.indexOf(dish.id)}));
      return {
        ...prevState,
        dishes,
      }
    });

    const response = await new ApiLibrary(language).menu.dishes.changeOrder(categoryId, dishIds);

    if (!response?.data) {
      setState((prevState) => ({
        ...prevState,
        dishes,
      }));
      return;
    }
  }

  return {
    state,

    fetchCards,
    setSelectedCard,
    addCard,
    updateCard,
    removeCard,
    changeCardsOrder,

    fetchCategories,
    setSelectedCategory,
    addCategory,
    updateCategory,
    removeCategory,
    changeCategoriesOrder,

    fetchDishes,
    setSelectedDish,
    addDish,
    updateDish,
    removeDish,
    changeDishesOrder,
  };
}