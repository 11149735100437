import "../../css/filters.css";
import React from "react";
import FiltersHidingHomeBodyComponent from "./FiltersHidingHomeBodyComponent";
import { IconButton } from "rsuite";
import { FaArrowLeft } from 'react-icons/fa';

function FiltersHidingHomeComponent({ setFilterData, data, isOpen, SetFilterClose, filterButtonAppearance, configuration}) {

  return (
    <div id="filters" className={`filter-menu-style ${isOpen ? 'active': ''}`}>
      <div className="margin-top-mx padding-right-md">
        <div className="margin-bottom-mx padding-left-xl">
          <IconButton icon={<FaArrowLeft size={15}/>} circle appearance={filterButtonAppearance} onClick={() => SetFilterClose()}/>
        </div>
        {data && (
                  <FiltersHidingHomeBodyComponent
                    setFilterData={setFilterData}
                    data={data}
                    configuration={configuration}
                    SetFilterClose={SetFilterClose}
                  />
                )}
        </div>
    </div>
  );
}

export default FiltersHidingHomeComponent;
