import { useI18nContext } from '../../../i18n/context/context';
import QuillEditor from '../QuillEditor';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button'

import { useEffect, useState } from 'react';
import ToggleWithLabel from '../../ToggleWithLabel';
import { useQuillEditor } from '../useQuillEditor';
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';

const DescriptionEditorComponent = ({restaurantCode}) => {
  const { quillRef, getContent, setContent, quill } = useQuillEditor();
  const { language, translate } = useI18nContext();
  const [isEnabled, setEnabled] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const setChanges = (val) => {
    setCanSave(true)
  }

  useEffect(() => {
    if(!!quill){
      if (quill && setChanges){
        quill.on("text-change", (delta) => {
          setChanges(quill.root.innerHTML)
        });
      }
    }
  }, [quill])

  useEffect(() => {
    const loadData = async () => {
      try {
        const description = await new ApiLibrary(language).restaurants.description.get(restaurantCode, language);
        setContent(description.content);
      } catch(error) {
        throw new Error('No description available!');
      }
    }

    if (setContent) {
      loadData();
    }
  }, [setContent])

  const handleSave = async () => {
    try {
      await new ApiLibrary(language).restaurants.description.updateOrCreate(restaurantCode, {
        name: 'test',
        content: getContent()
      })
      setCanSave(false)
    } catch(error) {
      throw new Error('No announcement available!');
    }
  }

  if (!restaurantCode) {
    return 'No restaurant selected';
  }

  return (
    <Container className="quill-editor-container">
      <div>
        <QuillEditor
          quillRef={quillRef}
        />
      </div>
      <div className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave(getContent())}
          disabled={!canSave}
        >
          {translate('save')}
        </Button>
      </div>
    </Container>
  );
};

export default DescriptionEditorComponent;
