import { MdFullscreen } from "react-icons/md";
import VideoPlayerComponent from "../VideoPlayerComponent";

function PicturesComponent({ items, OpenModal }) {

    return (
      <div className="pictures-style">
        {!!items && 
              items.map((item, idx) => (
                <div key={idx} className="item-picture-container pictures-item-picture-container">
                  <MdFullscreen className="full-screen-icon" style={{pointerEvents: 'none'}} />
                    {(item.slice(-4) == ".jpg" || item.slice(-4) == ".png") &&
                      <img
                        src={item}
                        className="item-img-style pictures-img-style cursor-pointer"
                        onClick={() => {
                          OpenModal(null, items, idx);
                        }}
                      />
                    }
                    {!(item.slice(-4) == ".jpg" || item.slice(-4) == ".png") &&
                      <div className="pictures-video-container" onMouseDown={(e) => {
                        OpenModal(null, items, idx);
                        e.stopPropagation();
                      }}>
                        <VideoPlayerComponent
                          id="demo-player"
                          publicId={item}
                          className="item-video-style pictures-img-style cursor-pointer"
                          autoplay={false}
                          playIfFocus={true}
                          touchEndCallback={(e) => {
                            OpenModal(null, items, idx);
                          }}
                          currentTime={0}
                        />
                      </div>
                    }
                </div>
              ))
          }
      </div>
    );
  }
  
  export default PicturesComponent;