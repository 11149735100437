export const useLogger = () => {
  return {
    error: (...message) => {
      console.error(...message);
    },
    warn: (...message) => {
      console.warn(...message);
    },
    info: (...message) => {
      console.info(...message);
    }
  }
}