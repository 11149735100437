// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toogler-container{
    display: flex;
    border: 1px solid #e5e5ea;
    border-radius: 4px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.toogler-item{
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    border: 1px;
}

.toogler-item:hover{
    color: var(--sefooColor);
}

.toogler-item-selected{
    color: var(--secondSefooColor) !important;
    border: 1px solid #e5e5ea;
    border-radius: 4px;
}

.toogler-item-selected-color{
    background-color: var(--sefooColor);
    color: var(--backgroundColor);
    border-radius: 4px;
}

.toogler-item-selected-color:hover{
    color: var(--backgroundColor);
}
`, "",{"version":3,"sources":["webpack://./src/directives/TooglerDirectiveStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mCAAmC;IACnC,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".toogler-container{\n    display: flex;\n    border: 1px solid #e5e5ea;\n    border-radius: 4px;\n    width: fit-content;\n}\n\n.toogler-item{\n    cursor: pointer;\n    padding: 5px 10px 5px 10px;\n    border: 1px;\n}\n\n.toogler-item:hover{\n    color: var(--sefooColor);\n}\n\n.toogler-item-selected{\n    color: var(--secondSefooColor) !important;\n    border: 1px solid #e5e5ea;\n    border-radius: 4px;\n}\n\n.toogler-item-selected-color{\n    background-color: var(--sefooColor);\n    color: var(--backgroundColor);\n    border-radius: 4px;\n}\n\n.toogler-item-selected-color:hover{\n    color: var(--backgroundColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
