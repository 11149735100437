import "../../../css/filters.css";
import React from "react";
import FilterRestaurantHidingComponent from "./FilterRestaurantHidingComponent";
import { IconButton } from "rsuite";
import { FaArrowLeft } from 'react-icons/fa';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useI18nContext } from '../../../i18n/context/context';

function FilterHidingComponent({ SetFilterData, config, isOpen, SetFilterClose, filterButtonAppearance, filters}) {
  return (
    <div className={`filter-menu-style ${isOpen ? 'active': ''}`}>
      <Modal
        id="filters"
        className={`filter-menu-modal-style ${isOpen ? 'active': ''}`}
        open={isOpen}
        onClose={SetFilterClose}
      >
        <Box className={`filter-menu-style ${isOpen ? 'active': ''}`}>
        <div className="margin-top-mx padding-right-md">
        <div className="margin-bottom-mx padding-left-xl">
          <IconButton icon={<FaArrowLeft size={15}/>} circle appearance={filterButtonAppearance} onClick={() => SetFilterClose()}/>
        </div>
        {config && (
                  <FilterRestaurantHidingComponent
                    SetFilterData={SetFilterData}
                    config={config}
                    SetFilterClose={SetFilterClose}
                    filters={filters}
                  />
                )}
        </div>
        </Box>
      </Modal>
      
    </div>
  );
}

export default FilterHidingComponent;
