import "./ModalItem.css";
import { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import { IoClose } from "react-icons/io5";
import ModalItemHorizontalComponent from "./ModalItemHorizontalComponent";
import ModalItemVerticalComponent from "./ModalItemVerticalComponent";


function ModalItemComponent({dish, setOpenModal}) {
    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

  useEffect(() => {
  }, [dish])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className="modal-item-container">
          {document.documentElement.clientWidth < document.documentElement.clientHeight &&
            <ModalItemVerticalComponent dish={dish} handleClose={handleClose}/>
          }
          {document.documentElement.clientWidth >= document.documentElement.clientHeight &&
            <ModalItemHorizontalComponent dish={dish} handleClose={handleClose} />
          }
        </div>        
      </Modal>
    </>
    
  );
}

export default ModalItemComponent;
