import "../../css/filters.css";
import React, { useState, useEffect } from "react";
import TypeFilterComponent from "../base/left/filters/TypeFilterComponent";
import FilterElementComponent from "../base/left/filters/FilterElementComponent";
import useScreenDetector from "../../hooks/useScreenDetector";
import InputHourDirectiveComponent from "../../directives/InputHourDirectiveComponent";
import { CircularProgress } from '@mui/material';
import { useI18nContext } from '../../i18n/context/context';

function FiltersHomeBodyComponent({ setFilterData, configuration, filters, SetFilterClose }) {
  const { translate } = useI18nContext();
  const { fixElementsStyle } = useScreenDetector();
  
  useEffect(() => {
    fixElementsStyle();
  }, [])

  const SetRestaurantTypeFilterData = (type) => {
    setFilterData({
      types: [type.restaurantType]
    });
  }

  const SetAvailabilityFilterData = (item) => {
    setFilterData({
      availableAt: [item.restaurantAvailability]
    });
  }

  if (!configuration) {
    return (
      <div className="">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="">
      {/* {!!configuration.types.isVisible && <FilterElementComponent
        label={translate("restaurantType")}
        selected={false}
        component={
          <TypeFilterComponent
            SetFilterValue={SetRestaurantTypeFilterData}
            data={configuration.types.items}
            objectKey="restaurantType"
          />
        }
      />}
      {!!configuration.availableAt.isVisible && <FilterElementComponent
        label={translate("restaurantAvailability")}
        selected={false}
        component={
          <TypeFilterComponent
            SetFilterValue={SetAvailabilityFilterData}
            data={configuration.availableAt.items}
            objectKey="restaurantAvailability"
          />
        }
      />}
      <InputHourDirectiveComponent /> */}
    </div>
  );
}

export default FiltersHomeBodyComponent;
