import React from "react";
import { InputGroup, InputNumber } from 'rsuite';
import { IoClose } from "react-icons/io5";
import { useI18nContext } from "../../../../i18n/context/context";
import SelectDirectiveComponent from "../../../../directives/SelectDirectiveComponent";


function MenuItemEditPriceComponent({ prices, currencies, setPrice, setCurrency }) {
    const { translate, language } = useI18nContext();  


    const onChangePriceValue = (idx, val) => {
        setPrice(idx, val)
    }

    const onChangeCurrency = (idx, curr) => {
        setCurrency(idx, curr)
    }

    return (
        <div className="">
            {!!prices && 
                <div className="price-body-inside">
                  {[...prices, {value: "", currency: ""}].map((item, idx) => (
                    <div className="price-single-full-element-edit" key={idx}>
                      <InputGroup className={`price-input ${!!item.value ? "sd-selected" : ""}`}>
                        <InputNumber 
                          className="price-input-inside"
                          placeholder={translate("price")}
                          min={0}
                          value={item.value} 
                          onChange={(val, e) => onChangePriceValue(idx, val)} step={0.01} />
                        <InputGroup.Addon className="input-number-clear">
                          {!!item.value && 
                            <IoClose className="sd-clear-button"
                              onClick={(e) => {
                                onChangePriceValue(idx, "")
                              }} 
                          />}
                        </InputGroup.Addon>
                      </InputGroup>
                      {!!item.value &&
                        <SelectDirectiveComponent 
                          options={currencies.map(x => ({ text: x, value: x }))}
                          clearable={false}
                          defaultValue={currencies[0]}
                          placeholder={translate("currency")}
                          onChange={(val) => onChangeCurrency(idx, val)}
                          selectedOption={{text: item.currency, value: item.currency}}/>
                      }
                    </div>
                  ))}
                </div>
              }
        </div>
    );
}

export default MenuItemEditPriceComponent;
