import React, { useMemo } from "react";
import { Dropdown } from "rsuite";
import { getFormattedCurrency } from '../../../../helpers/formatting';

function CurrencyFilterComponent({ SetSelectedCurrency, data, value }) {
  const currencyData = useMemo(() => (
    data.map((item) => ({ label: item, value: item }))
  ), [data]);

  return (
    <span>
      {currencyData.length == 1 && <span>( {getFormattedCurrency(value)} )</span>}
      {!!currencyData && currencyData.length > 1 && (
        <span>
          <span>(</span>
          <Dropdown activeKey={value} title={getFormattedCurrency(value)} placement="bottomEnd">
            {currencyData.map((item, key) => (
              <Dropdown.Item
                key={key}
                onSelect={SetSelectedCurrency}
                eventKey={item.value}
              >
                {getFormattedCurrency(item.label)}
              </Dropdown.Item> 
            ))}
          </Dropdown>
          <span>)</span>
        </span>
      )}
    </span>
  );
}

export default CurrencyFilterComponent;
