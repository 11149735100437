import { BaseApi } from '../shared/baseApi';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../shared/fetch';

export class CategoriesApi extends BaseApi {
  getList(catalogId) {
    const searchParams = new URLSearchParams({
      catalogId,
    });
    return createGetRequest(`category?${searchParams}`, this._language);
  }

  getDishes(categoryId) {
    return createGetRequest(`category/${categoryId}/products`, this._language);
  }

  add(catalogId, payload) {
    const searchParams = new URLSearchParams({
      catalogId,
    });
    return createPostRequest(`category?${searchParams}`, JSON.stringify(payload), this._language);
  }

  update(categoryId, payload) {
    return createPutRequest(`category/${categoryId}`, JSON.stringify(payload), this._language);
  }

  changeOrder(catalogId, categoriesIds) {
    const searchParams = new URLSearchParams({
      catalogId,
    });
    return createPutRequest(`category/xd/order?${searchParams}`, JSON.stringify({ids: categoriesIds}), this._language);
  }

  remove(categoryId) {
    return createDeleteRequest(`category/${categoryId}`, this._language);
  }
}