import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from "react-cookie";
import stringsPl from "../language/strings_pl.json";
import stringsEnGb from "../language/strings_en-gb.json";
import stringsDe from "../language/strings_de.json";
import stringsEs from "../language/strings_es.json";
import stringsIt from "../language/strings_it.json";
import stringsUk from "../language/strings_uk.json";
import languages from "../language/languages.json";

const I18nContext = createContext({});

const useI18nStore = (defaultLanguage = 'pl') => {
  const [cookies, setCookie] = useCookies(["SEFOO_LANGUAGE_COOKIE"]);
  const [language, setLanguage] = useState(cookies["SEFOO_LANGUAGE_COOKIE"] ?? defaultLanguage);

  const languageList = useMemo(() => (
    Object.entries(languages).map(([value, label]) => ({label, value}))
  ), [languages]);

  const translate = useCallback((translationKey, lang=null) => {
    const languageMapper = {
      "pl": stringsPl,
      "en-gb": stringsEnGb,
      "de": stringsDe,
      "es": stringsEs,
      "uk": stringsUk,
      "it": stringsIt,
    }
    return languageMapper[lang ?? language]?.[translationKey] ?? `TODO: (${lang ?? language})${translationKey}`;
  }, [language]);

  return {
    language,
    setLanguage: (language, shouldReload = false) => {
      setLanguage(language);
      setCookie("SEFOO_LANGUAGE_COOKIE", language, { path: "/" });
      shouldReload && window.location.reload(false);
    },
    translate,
    languageList
  }
}

export const useI18nContext = () => useContext(I18nContext);

export const I18nContextProvider = ({ children }) => {
  const store = useI18nStore();

  return (
    <I18nContext.Provider value={store}>
      {children}
    </I18nContext.Provider>
  );
}