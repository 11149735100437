import "../../css/restaurant.css";
import React from "react";
import RestaurantResultItemComponent from "./RestaurantResultItemComponent";


function RestaurantItemComponent({ restaurant }) {

  return (
    <div>
      {!!restaurant && 
      <div key={restaurant.id}>
        <div className="restaurant-item-style">
          <RestaurantResultItemComponent restaurant={restaurant}/>
        </div>
      </div>}
    </div>
    
  );
}

export default RestaurantItemComponent;
