import React from "react";
import DishItemForRestaurantComponent from "./DishItemForRestaurantComponent";
import { Loader } from 'rsuite';

function DishItemsListPerRestaurantComponent({ OpenModal, dishes }) {
  if (!dishes) {
    return <div><Loader size="lg" className='dropzone-icon' /></div>;
  }

  return (
    <div className="padding-left-md padding-right-md">
      {dishes.length > 0 ?
        dishes.map((dish, idx) => (
          <div key={idx}>
              <DishItemForRestaurantComponent
                OpenModal={OpenModal}
                dish={dish}
              />
          </div>
        )) : ''}
    </div>
  );
}

export default DishItemsListPerRestaurantComponent;
