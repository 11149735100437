import "../../css/filters.css";
import React from "react";
import FiltersHomeBodyComponent from "./FiltersHomeBodyComponent";

function FiltersHidingHomeBodyComponent({ setFilterData, data, SetFilterClose, configuration }) {
  
  return (
    <div id="filtersAutoContainer" className="filter-hidding-container-style sticky-zero-position-style">
      <div className="filter-overflow-auto">
        <FiltersHomeBodyComponent
          setFilterData={setFilterData}
          data={data}
          configuration={configuration}
          SetFilterClose={SetFilterClose}
        />
        <div className="fake-field-mx"></div>
      </div>
    </div>
  );
}

export default FiltersHidingHomeBodyComponent;
