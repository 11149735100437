import React, { useCallback } from "react";
import InputDirectiveComponent from "../../../../directives/InputDirectiveComponent";
import { useI18nContext } from '../../../../i18n/context/context';

function PriceMinFilterComponent({ SetFilterValue, data, value }) {
  const { translate } = useI18nContext();

  const abortMin = (val) => {
    SetFilterValue(Number(val));
  }

  const ClearMinPrice = useCallback(() => {
    SetFilterValue(null);
  }, [])

  return (
    <div className="price-input-style">
      {!!data.minPrice.visible && 
        <InputDirectiveComponent
          defValue={value}
          placeholder={translate("from")}
          SetValue={SetFilterValue}
          type="number" 
          focusOutCallback={abortMin}
          clearCallback={ClearMinPrice}
        />
      }
    </div>
  );
}

export default PriceMinFilterComponent;