import React from "react";


function DishItemPriceComponent({ prices, priceVariants, className }) {

  return (
    <>
        {!!prices && prices.length > 0 && 
            <div className={`price-body-inside ${className}`}>
                {prices.map((item, idx) => (
                    <div className="price-single-full-element" key={idx}>
                        <div className="label">{item.value} {item.currency}</div>
                    </div>
                ))}
            </div>
        }
        {!!priceVariants && priceVariants.length > 0 && 
            <div>
                <div className={className}>
                  {priceVariants.map((variant, idx) => (
                    <div key={idx} className="display-flex">
                      <div className="price-variant label">
                        {variant.variant}
                      </div>
                      <div className="price-variant-prices">
                        <div className="price-body-inside">
                            {variant.prices.map((item, idx) => (
                                <div className="price-single-full-element" key={idx}>
                                    <div className="label">{item.value} {item.currency}</div>
                                </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
        }
    </>

  );
}

export default DishItemPriceComponent;