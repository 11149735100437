import React, { useCallback, useEffect, useState } from "react";
import { Input, Button } from 'rsuite';
import DropzoneDirectiveComponent from "../../../directives/DropzoneDirectiveComponent";
import AutoCompleteDirectiveComponent from "../../../directives/AutoCompleteDirectiveComponent";
import AdminBasicOpenCloseComponent from "./AdminBasicOpenCloseComponent";
import AdminBasicMapComponent from "./AdminBasicMapComponent";
import { useI18nContext } from "../../../i18n/context/context";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import ToggleWithLabel from "../../ToggleWithLabel";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";

function AdminBasicComponent({ restaurant, handleChange }) {
  const { translate, language } = useI18nContext();
  const [model, setModel] = useState(null);
  const [isEnabled, setToogleEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const restaurantTypes = [{id: 0, text: "Winiarnio-kawiarnia"}, {id: 1, text: "Polskie jedzenie"}]


  useEffect(() => {
    if(restaurant){
      let thisModel = {...restaurant};
      thisModel.restaurantTypeObject = restaurantTypes.filter(x => x.text == thisModel.restaurantType)[0]
      setModel(thisModel);
    }
  }, [restaurant])

  const setHeaderPhotoUrl = async ([file]) => {
    let thisModel = {...model};
    if(file){
      try {
        const response = await new ApiLibrary().common.add(file);
        thisModel.mainPhotoUrl = response.data?.url;
      } catch (error) {
        console.error(error);
      }
    } else {
      await new ApiLibrary().common.removeByUrl(restaurant?.mainPhotoUrl);
      thisModel.mainPhotoUrl = null;
    }
    setModel(thisModel)
  }


  const setLogoPhotoUrl = async ([file]) => {
    let thisModel = {...model};
    if(file){
      try {
        const response = await new ApiLibrary().common.add(file);
        thisModel.logoPhotoUrl = response.data?.url;
      } catch (error) {
        console.error(error);
      }
    } else {
      await new ApiLibrary().common.removeByUrl(restaurant?.logoPhotoUrl);
      thisModel.logoPhotoUrl = null;
    }
    setModel(thisModel)
  }

  const setLocation = (location) => {
    let thisModel = {...model};
    thisModel.location = {x: location.lat, y: location.lng};
    setModel(thisModel);
  }

  const handleSave = async () => {
    setIsLoading(true);
    await new ApiLibrary().restaurants.general.update(restaurant.id, model)
    handleChange(model);
    setIsLoading(false);
  }

  return (
    <div className="admin-basic-data-container">
      {isLoading &&
        <LoaderDirectiveComponent />
      }
    {!! model &&
      <div className="admin-basic-data-inside-container">
        <div className="margin-top-xl">
          <DropzoneDirectiveComponent onlyPhoto onUpload={setHeaderPhotoUrl} item={model?.mainPhotoUrl} containerClassName="restaurant-header-picture" photoClassName="restaurant-header-picture-uploaded" emptyClassName="restaurant-header-picture" />
        </div>
        <div className="display-flex">
          <div className="admin-basic-data-picture-container">
            <DropzoneDirectiveComponent onlyPhoto onUpload={setLogoPhotoUrl} item={model?.logoPhotoUrl} className="restaurant-picture" />
          </div>
          <div className="restaurant-name-cointener">
            <div className="restaurant-name-style">
              {model.restaurantName}
            </div>
          </div>
        </div>


       <div className="">
          <div className="admin-basic-edit-item-field-text font-xl margin-top-xl">
            <AutoCompleteDirectiveComponent inputClassName={`font-xl ${!model.restaurantType ? "edit-input-error" : ""}`}
              clearable={false}
              value={model?.restaurantTypeObject}
              onChange={(val) => {
                let thisModel = {...model};
                let types = restaurantTypes.filter(x => x.text?.toLocaleLowerCase() == val?.text?.toLocaleLowerCase())
                thisModel.restaurantTypeObject = types.length > 0 ? types[0] : val;
                thisModel.restaurantType = thisModel.restaurantTypeObject?.text;
                setModel(thisModel);
              }} 
              options={!!model.restaurantTypeObject?.text ? restaurantTypes.filter(x => x.text?.toLocaleLowerCase().includes(model.restaurantTypeObject.text?.toLocaleLowerCase())) : []}
              placeholder={translate("restaurantType")}
            /> 
          </div>
          
          <div className="margin-top-xl">
            <div className="label margin-bottom-sm">{translate("address")}</div>
            <div className="edit-items-field">
              <div className="margin-mx admin-basic-address-city">
                <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.city ? "edit-input-error" : ""} ${!!model.address?.city ? "sd-selected" : ""}`}
                  placeholder={translate("city")}
                  defaultValue={model?.address?.city}
                  onChange={(val, e) => {
                    let thisModel = {...model};
                    thisModel.address.city = val;
                    setModel(thisModel);
                  }}
                />
            </div>
            <div className="margin-mx admin-basic-address-street">
              <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.street ? "edit-input-error" : ""} ${!!model.address?.street ? "sd-selected" : ""}`}
                placeholder={translate("street")}
                defaultValue={model?.address?.street}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.address.street = val;
                  setModel(thisModel);
                }}
              />
            </div>
            <div className="margin-mx admin-basic-address-street-number">
              <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.streetNumber ? "edit-input-error" : ""} ${!!model.address?.streetNumber? "sd-selected" : ""}`}
                placeholder={translate("streetNumber")}
                defaultValue={model?.address?.streetNumber}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.address.streetNumber = val;
                  setModel(thisModel);
                }}
              />
            </div>
            </div>
          </div>
    
          <div className="display-flex">
            <AdminBasicMapComponent isEditable={isEnabled} restaurant={restaurant} setLocation={setLocation}/>
            <ToggleWithLabel
              label={translate("editMarkerMap")}
              checked={isEnabled}
              onChange={setToogleEnabled}
            />
          </div>
        </div>
      </div>
    }
    {!!model && 
      <div className='save-button-container'>
      <Button
        color="green"
        appearance="primary"
        onClick={() => handleSave()}
        disabled={!(model.restaurantType && model.address.city && model.address.street && model.address.streetNumber)}
      >
        {translate('save')}
      </Button>
    </div>
    }
    </div>
    
  );
}

export default AdminBasicComponent;
